import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import Button from "../Button";
import SearchBox from "./components/SearchBox";
import { useGlobalStateContext } from "@app/utils/contexts.js/GlobalStateProvider";
import { Hamburger, X } from "@app/components/Icon/icons";
import Logo from "../Logo";
import { useAuthContext } from "@app/utils/contexts.js/AuthProvider";
import { capitalize } from "@app/components/helpers";
import useWindowSize from "@app/utils/hooks/useWindowSize";
import { MAX_MOBILE_WIDTH } from "@app/constants";

const Header = () => {
  const { state, setState } = useGlobalStateContext();
  const { user } = useAuthContext();
  const { width } = useWindowSize();
  // const {pathname}
  const { pathname } = useLocation();
  // const authorized = true;
  let path =
    pathname === "/"
      ? "Home"
      : capitalize(
          pathname === "/list" ? "Watch List" : pathname.slice(1).split("/")[0]
        );
  const handleHamburgerClick = (e) => {
    e.stopPropagation();
    // const listener = function() {
    //   if(isSpecialModalVisible){
    //     setIsSpecialModalvisible((value: boolean) => false)
    //   }
    // }
    // console.log("IsSpecialModalvisible: ", isSpecialModalVisible);
    // if(isSpecialModalVisible) setIsSpecialModalvisible(false)
    // if(!isSpecialModalVisible) setIsSpecialModalvisible(true)
    // setIsSpecialModalvisible((isSpecialModalVisible) => !isSpecialModalVisible);
    // document.body.addEventListener("click" , listener)
    // setIsSpecialModalvisible((value: boolean) => !value)
    setState((value) => ({ ...state, isNavOpen: !state?.isNavOpen }));
  };

  useEffect(() => {
    const listener = () => setState({ ...state, isNavOpen: false });
    document.body.addEventListener("click", listener);
    return () => document.body.removeEventListener("click", listener);
  }, [setState, state]);
  return (
    <div className="md:px-2 px-4 md:py-4 pt-3 pb-1 space-y-10 items-center">
      <header className="md:min-h-0 min-h-24  bg-transparent flex space-between items-center  lg:gap-10 gap-16 md:gap-3">
        <button
          tabIndex={-1}
          className="flex-shrink-0 hidden md:flex flex-col justify-center items-center "
          onClick={handleHamburgerClick}
        >
          <span
            tabIndex="-1"
            className="w-7 h-7  text-[#ffffff] flex items-center "
          >
            {state?.isNavOpen ? <X /> : <Hamburger />}
          </span>
        </button>

        <div className=" flex flex-auto md:flex-row-reverse justify-between md:justify-between   md:gap-2 lg:gap-16 gap-8 items-center ">
          <h3 className="text-[#FFFFFF] font-semibold text-h3 md:hidden flex-shrink-0">
            {path}
          </h3>
          <SearchBox classes="peer" />
          {width <= MAX_MOBILE_WIDTH && (
            <Logo hide={false} className="w-16  md:block hidden" />
          )}

          {!user && (
            <div className="flex items-center gap-2 js justify-between shrink-0 md:hidden">
              <Link to="/auth/signup">
                <Button variant="link">Sign Up</Button>
              </Link>
              <Link to="/auth/signin">
                <Button variant="transparent">Sign In</Button>
              </Link>
            </div>
          )}
        </div>
      </header>
      {/* <FiltersBanner /> */}
    </div>
  );
};

export default Header;
