import Toast from "@app/components/common/Toast";
import OTPInput from "@app/components/OTPInput";
import { storage } from "@app/utils";
// import { getStorage } from "firebase/storage";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
// import AuthForm from "AuthForm";
import AuthForm from "@app/views/Authentication/components/AuthForm";
import { confirmChangeEmail } from "@app/api/authapi";
import { INVALID_AUTH_MESSAGE } from "@app/constants";
import { useAuthContext } from "@app/utils/contexts.js/AuthProvider";
const REQUIRED_OTP_LENGTH = 6;

const VerifyAccount = () => {
  const [otp, setOtp] = useState("");
  const navigate = useNavigate();
  const { logout } = useAuthContext();
  const [isVerifyingUser, setIsVerifyingUser] = useState(false);

  const handleSubmit = async (e) => {
    e?.preventDefault();

    if (otp.length === REQUIRED_OTP_LENGTH) {
      setIsVerifyingUser(true);
      const payload = {
        newEmail: storage.get("new_email"),
        otpId: storage.get("otp_id"),
        code: otp,
      };

      confirmChangeEmail(payload)
        .then((res) => {
          if (res.statusCode === 200) {
            toast.success(
              <Toast
                toastType={"success"}
                message={"Email changed successfully!"}
              />,
              {
                toastId: "toast-successful-new-email-verification",
              }
            );
            storage.clear("new_email");
            storage.clear("otp_id");
          }
        })
        .then(() => navigate("/account"))
        .catch((error) => {
          if (error?.response?.data?.message === INVALID_AUTH_MESSAGE) logout();

          console.log("error in verifying user: ", e);
        })
        .finally(() => setIsVerifyingUser(false));
      // console.log({ res });
    } else {
      // alert("incom")
      toast.error(<Toast message="Incomplete OTP" toastType="error" />, {
        toastId: "toast-incomplete-otp",
      });
    }
  };

  //   const handleResendOTP = () => {
  //     setIsResendingOTP(true);
  //     const RESEND_PURPOSE = VERIFY_FOR_RESET ? FORGET_PASSWORD : REGISTER;
  //     resendOTP(storage.get("email"), RESEND_PURPOSE)
  //       .then((res) => {
  //         toast.success(<Toast message={res.message} toastType="success" />, {
  //           toastId: "toast-successful-otp-resend",
  //         });
  //         storage.set("id", res.data.id);
  //       })
  //       .catch((e) => console.log("error while resending OTP: ", e))
  //       .finally(() => setIsResendingOTP(false));
  //   };

  //   useEffect(() => {
  //     if (!email || !id) {
  //       navigate("/auth/signup");
  //     }
  //   }, [email, id, navigate]);

  return (
    <section className="w-full h-full flex items-center justify-center">
      <AuthForm
        handleSubmit={handleSubmit}
        isLoading={isVerifyingUser}
        text={"Confirm"}
        title="Verify Email"
        description={"Enter OTP sent to new email provided"}
        disableButton={isVerifyingUser}
        nonAuth
      >
        <div>
          <OTPInput
            otpValue={otp}
            setOtpValue={setOtp}
            handleSubmit={handleSubmit}
            otpLength={REQUIRED_OTP_LENGTH}
          />
        </div>
        {/* <Button
        className="-mb-5 pt-5 text-[#fff] self-center"
        variant="link"
        disabled={isVerifyingUser}
        onClick={handleResendOTP}
        // loading={isResendingOTP}
      >
        {isResendingOTP ? "..." : "Resend OTP"}
      </Button> */}
      </AuthForm>
    </section>
  );
};

export default VerifyAccount;
