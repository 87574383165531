import React, { forwardRef } from "react";
// import MoviePoster from "@app/assets/images/moviecard2.jpg";
import {
  CircledX,
  ListWithTick,
  // Play,
  ArrowDropddownCircle,
  // CircularArrow,
  ListWithPlus,
  Heart,
} from "../Icon/icons";
// import MovieProgress from "./MovieProgress";
// import { Tooltip } from "@mui/material";
import Tooltip from "../Tooltip";
// import { MAX_MOBILE_WIDTH, SERIES } from "@app/constants";
import { useErrorHandler } from "@app/utils/hooks";
import {
  useAddWatchList,
  useRemoveWatchList,
  useWatchList,
} from "@app/utils/hooks/reactQuery/watchList";
import classNames from "classnames";
import {
  useAddFavourite,
  useFavorites,
  useRemoveFavourite,
} from "@app/utils/hooks/reactQuery/favorite";
// import { width } from "@mui/system";
// import useWindowSize from "@app/utils/hooks/useWindowSize";
import { useLocation, useNavigate } from "react-router-dom";
import { sendAnalyticsToFirebase } from "@app/config/firebase.config";

// const ICON_GROUP = [
//   {
//     icon: Play,
//     tooltip_message: "Play",
//   },
//   {
//     icon: ListWithTick,
//     tooltip_message: "Remove from my list",
//   },
//   {
//     icon: CircledX,
//     tooltip_message: "Remove from row",
//   },
// ];

const IconButton = forwardRef(
  ({ icon: Icon, handleClick, disabled, isLoading }, ref) => (
    <button
      disabled={isLoading || disabled}
      className={classNames(
        "w-5 h-5 text-neutral-01 hover:text-[#fff] cursor-pointer disabled:opacity-40",
        {
          "cursor-not-allowed": isLoading,
        }
      )}
      onClick={handleClick}
      ref={ref}
    >
      {" "}
      {<Icon />}
    </button>
  )
);

const MoviePreviewCard = ({ unFinished, movie, handleThumbnailPlayClick }) => {
  // console.log({ handleThumbnailPlayClick });
  // const { width } = useWindowSize();
  const { data: watchList, isLoading: isGettingList } =
    useErrorHandler(useWatchList);
  const { data: favourites, isLoading: isGettingFavourites } =
    useErrorHandler(useFavorites);
  // if (movie && watchList && movie?.id === watchList[0]?.id) {
  //   console.log({ watchList, id: movie.id, title: movie.title });
  // }
  const { mutate: removeFromList, isLoading: isRemovingFromList } =
    useErrorHandler(useRemoveWatchList);
  const { mutate: removeFromFavourites, isLoading: isRemovingFromFavourites } =
    useErrorHandler(useRemoveFavourite);

  const { mutate: addToList, isLoading: isAddingToList } = useErrorHandler(
    useAddWatchList,
    movie
  );
  const { mutate: addToFavourites, isLoading: isAddingToFavourites } =
    useErrorHandler(useAddFavourite, movie);

  // const [fourthIcon, setFourthIcon] = useState(false);

  const isUserInWatchList = watchList?.some(
    (item) => item?.id === (movie?.movie ? movie?.movie?.id : movie?.id)
  );
  const isUserInFavourite = favourites?.some(
    (item) => item?.id === (movie?.movie ? movie?.movie?.id : movie?.id)
  );
  const navigate = useNavigate();
  // console.log({ movie });
  const { pathname } = useLocation();
  const handleThumbnailClick = () => {
    // if (width > MAX_MOBILE_WIDTH) {
    navigate(pathname === "/" ? `/home/${movie.id}` : `${movie.id}`);
    // }
  };
  // const [addToList, setAddToList] = useState(false);
  return (
    <div className="bg-[#262626] rounded-[2px] overflow-hidden md:w-[230px] w-[300px]  group-hover:block h-max flex flex-col shadow-2xl">
      <div className="w-full aspect-[500/180] overflow-hidden flex items-center justify-center">
        <img className="object-cover" src={movie?.poster} alt={movie?.title} />
      </div>
      <div className="px-3 py-4">
        <div className="flex justify-between ">
          <div className="space-x-2">
            {/* <Tooltip message="Play">
              <IconButton
                icon={Play}
                handleClick={(e) => {
                  // if (width > MAX_MOBILE_WIDTH) {
                  e.stopPropagation();
                  handleThumbnailPlayClick();
                  // }
                }}
              />
            </Tooltip> */}
            <Tooltip
              message={
                isUserInWatchList
                  ? "Remove from watch list"
                  : "Add to watchlist"
              }
            >
              <IconButton
                disabled={isGettingList}
                isLoading={isAddingToList || isRemovingFromList}
                icon={isUserInWatchList ? ListWithTick : ListWithPlus}
                handleClick={(e) => {
                  // if (width > MAX_MOBILE_WIDTH) {
                  e.stopPropagation();
                  // }
                  if (isUserInWatchList) {
                    sendAnalyticsToFirebase("removed_from_watchlist", {
                      movie_id: movie?.id,
                    });
                  } else {
                    sendAnalyticsToFirebase("movie_added_to_watchlist", {
                      movie_id: movie?.id,
                      total_watchlist_count: watchList?.length + 1,
                    });
                  }
                  return isUserInWatchList
                    ? removeFromList(
                        movie?.movie ? movie?.movie?.id : movie?.id
                      )
                    : addToList(movie?.movie ? movie?.movie : movie);
                }}
              />
            </Tooltip>

            {/* {unFinished && ( */}
            {/* {unFinished && ( */}
            <Tooltip
              message={
                isUserInFavourite
                  ? "Remove from Favourites"
                  : "Add to Favourites"
              }
            >
              {/* <Tooltip message="Remove from Row"> */}
              {/* <IconButton icon={CircledX} ={() => {}} /> */}
              <IconButton
                // icon={CircledX}
                disabled={isGettingFavourites}
                isLoading={isAddingToFavourites || isRemovingFromFavourites}
                icon={isUserInFavourite ? CircledX : Heart}
                handleClick={(e) => {
                  // if (width > MAX_MOBILE_WIDTH) {
                  e.stopPropagation();
                  // }
                  if (isUserInFavourite) {
                    sendAnalyticsToFirebase("removed_from_favorites", {
                      movie_id: movie?.id,
                    });
                  } else {
                    sendAnalyticsToFirebase("movie_added_to_favorites", {
                      movie_id: movie?.id,
                      total_favorites_count: favourites?.length + 1,
                    });
                  }
                  return isUserInFavourite
                    ? removeFromFavourites(
                        movie?.movie ? movie?.movie?.id : movie?.id
                      )
                    : addToFavourites(movie?.movie ? movie?.movie : movie);
                }}
              />
            </Tooltip>
            {/* )} */}
          </div>
          {!unFinished && (
            <Tooltip message={"More Info"}>
              <IconButton
                icon={ArrowDropddownCircle}
                // className="w-4 h-4 text-neutral-01 hover:text-[#fff]"
                handleClick={(e) => {
                  // if (width > MAX_MOBILE_WIDTH) {
                  e.stopPropagation();
                  // }
                  // setFourthIcon(!fourthIcon);
                  handleThumbnailClick();
                }}
              />
            </Tooltip>
          )}
        </div>
        <div className="mt-3">
          <p className="md:text-h1 text-h3 font-semibold two-lines">
            {movie?.title}
          </p>
          <p className="md:text-h1 text-h2 space-x-2 text-[#fff] mt-1  ">
            {!unFinished && (
              <>
                <span>{`[${movie?.yearOfRelease}]`}</span>
                <span>{movie?.viewRating}</span>

                {/* {movie.type === SERIES && <span>24 episodes</span>} */}
              </>
            )}
          </p>
        </div>
        {/*
        <p className="text-[10px] text-neutral-01">
          {" "}
          {unFinished ? (
            <MovieProgress unFinished={unFinished} />
          ) : (
            "War, Peace and Violence"
          )}{" "}
        </p> */}
      </div>
    </div>
  );
};

export default MoviePreviewCard;
