import React from "react";

const EpisodeThumbnail = ({ episode, handleEpisodeClick, movie }) => {
  return (
    <div
      className="text-[#fff] md:flex items-start gap-2 mx-auto px-1 md:aspect-auto aspect-[4/3] cursor-pointer hover:scale-95"
      onClick={() => handleEpisodeClick({ ...episode })}
    >
      <div className="md:hidden w-[90%] mx-auto aspect-[9/12] bg-[#262626] flex items-center justify-center">
        <img
          src={episode?.poster}
          alt={episode?.title}
          className=" md:hidden w-full h-full text-h1 inline-block"
        />
      </div>
      <div className="md:hidden w-[90%] mx-auto my-1">
   
        <p className="text-h1">
          <span className="font-semibold">Episode no:</span>{" "}
          {episode?.episodeNumber}
        </p>
        {/* <p>Episode }</p> */}
      </div>
      <div className="w-[100px] aspect-[16/9] md:block md:flex-shrink-0 hidden">
        <img src={episode?.poster} alt={episode?.title} />
      </div>
      <div className="text-h1 space-y-1 md:block hidden">
        <p className="">
          Title: {episode?.title} [episode_{episode?.episodeNumber}]
        </p>
        <p className="two-lines hover:block">{episode?.description}</p>
      </div>
    </div>
  );
};

export default EpisodeThumbnail;
