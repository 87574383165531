import * as React from "react";
const SvgCaretLeft = (props) => (
  <svg
    width={6}
    height={11}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.269 10.054a.696.696 0 0 1-.985 0L.34 6.11a.696.696 0 0 1 0-.985l3.945-3.946a.696.696 0 0 1 .985.985L1.816 5.616 5.269 9.07a.696.696 0 0 1 0 .984Z"
      fill="#fff"
    />
  </svg>
);
export default SvgCaretLeft;
