import classNames from "classnames";
import React, { useEffect, useRef, useState } from "react";
import Chevron from "../common/Chevron";
import { capitalize } from "../helpers";
import MoviePreviewCard from "./MoviePreviewCard";
import MoviesThumbnail from "./MoviesThumbnail";

const MoviesRow = ({ category = "Zoe and Zedas", unFinished, movies , handleCardClick}) => {
  const rowRef = useRef(null);
  const [, setIsMoved] = useState(false);

  const handleClick = (direction) => {
    setIsMoved(true);
    if (rowRef.current) {
      const { scrollLeft, clientWidth } = rowRef?.current;

      const scrollTo =
        direction === "left"
          ? scrollLeft - clientWidth
          : scrollLeft + clientWidth;
      rowRef?.current.scrollTo({ left: scrollTo, behavior: "smooth" });
    }
  };

  useEffect(() => {
    const thumbnails = rowRef?.current?.querySelectorAll("#thumbnail");
    // console.log({ thumbnails });
    const MouseOverHandler = () => {
            rowRef.current.style.height = "300px";
    };
    const MouseOutHandler = () => {
            rowRef.current.style.height = "max-content";
    };
    thumbnails.forEach((thumbnail) => {

      // thumbnail.addEventListener("mouseover", MouseOverHandler)
      // thumbnail.addEventListener("mouseout", MouseOutHandler)
    }
    );
    return () => {
      thumbnails.forEach((thumbnail) =>{

        thumbnail.removeEventListener("mouseover", MouseOverHandler)
        thumbnail.removeEventListener("mouseout", MouseOutHandler)
      }
      );
    };
    // console.log({ imgs: img });
  }, []);
  return (
    <>
      {/* <div className="my-3 w-full bg-secondary-05 h-10 "> */}
      <div className="my-1">
        <h2 className="font-normal text-h4 text-primary-06 md:text-h4 ">
          {capitalize(category)}
        </h2>
        <div className="relative group/row  ">
          {/* <ChevronLeftIcon
                    className='absolute top-0 bottom-0 left-2 z-40 m-auto h-9 w-9 cursor-pointer opacity-0 transition hover:scale-125 group-hover:opacity-100'
                    handleClick={() => handleClick("left")}
                /> */}
          <span>
            <Chevron
              direction="left"
              className="absolute top-0 bottom-0 left-0 z-40 m-auto h-9 w-9 cursor-pointer opacity-0 transition hover:scale-125 group-hover/row:opacity-100 md:hiden -translate-x-1/2"
              handleClick={() => handleClick("left")}
            />
          </span>
          <div
            ref={rowRef}
            className={classNames(
              //   "flex items-center gap-3 overflow-x-scroll md:gap-1 md:p-2  ",
              "flex gap-2  overflow-x-scroll scrollbar-hide  overflow-y-auto md:h-[200px] h-[250px]",
              // "flex gap-2  overflow-x-scroll scrollbar-hide  overflow-y-auto md:h-[250px] h-[340px]",
              {
                // "h-70": unFinished,
              }
            )}
          >
            {movies?.map((item, idx) => (
              <MoviesThumbnail
                key={item?.id}
                unFinished={unFinished}
                index={item.id}
                MoviePoster={item?.poster}
                title={item?.title}
                movie={item}
                handleCardClick={handleCardClick}
              >
                <MoviePreviewCard movie={item} id={item?.id} unFinished={unFinished}/>
              </MoviesThumbnail>
            ))}
            <div className="md:w-[150px] w-48 h-full aspect-[100/150] "></div>
          </div>
          {/* <ChevronRightIcon
            className="absolute top-0 bottom-0  right-2 z-40 m-auto h-9 w-9 cursor-pointer opacity-0 transition hover:scale-125 group-hover:opacity-100"
            handleClick={() => handleClick("right")}
          /> */}
          <span>
            <Chevron
              direction="right"
              className="absolute top-0 bottom-0  right-0 translate-x-1/2 z-40 m-auto h-9 w-9 cursor-pointer opacity-0 transition hover:scale-125 group-hover/row:opacity-100 md:hidden"
              handleClick={() => handleClick("right")}
            />
          </span>
        </div>
      </div>
    </>
  );
};

export default MoviesRow;
