import {
  // addEpisodeToMovie,
  // addMovie,
  // editEpisode,
  // editMovie,
  // getAllEpisodes,
  getHomeMovies,
  getHomePageTopSection,
  getMovieDetails,
  getWatchHistory,
  searchMovie,
  updateWatchHistory,
} from "@app/api/movieapi";

const { useQuery, useQueryClient, useMutation } = require("react-query");

const useHomeMovies = () => {
  const { isFetching, data, isError, error, isLoading } = useQuery({
    queryKey: "movies",
    // queryFn: () => getAllMovies(limit, lastMovieId),
    queryFn: getHomeMovies,
    retry: false,
  });
  return { isFetching, data, isError, error, isLoading };
};

const useEpisodes = (id) => {
  const { data, isError, error, isLoading } = useQuery({
    queryKey: ["episodes", id],
    queryFn: () => getMovieDetails(id),
    retry: false,
  });
  return { isLoading, data, isError, error };
};

// const fetchProjects = ()

// const useEpisodes = (id) => {
//   const { data, isError, error, isLoading } = useQuery({
//     queryKey: ["episodes", id],
//     queryFn: () => getAllEpisodes(id),
//     retry: false,
//   });
//   return { isLoading, data, isError, error };
// };

// const useInfiniteMovies = (id, limit) => {
//   const {
//     data,
//     error,
//     fetchNextPage,
//     hasNextPage,
//     isFetching,
//     isFetchingNextPage,
//     status,
//     isLoading,
//     isError,
//   } = useInfiniteQuery(
//     "movies",
//     ({ pageParam }) => {
//       // console.log({ pageParam });
//       // return getAllMovies({ lastMovieId: pageParam, limit });
//     },
//     {
//       keepPreviousData: true,
//       getNextPageParam: (lastMovie, allMovies) => {
//         // console.log({ allMovies });
//         const data = allMovies[allMovies.length - 1]?.data;
//         const last = data?.[data.length - 1];

//         // console.log({ data, last });
//         return last?.id;
//       },
//     }
//   );
//   return {
//     data,
//     error,
//     fetchNextPage,
//     hasNextPage,
//     isFetching,
//     isFetchingNextPage,
//     status,
//     isLoading,
//     isError,
//   };
// };

// const useEditMovie = () => {
//   const queryClient = useQueryClient();
//   const { mutate, data, isError, isSuccess, isLoading } = useMutation({
//     mutationFn: editMovie,
//     onSuccess: () => {
//       // Invalidate and refetch

//       queryClient.invalidateQueries({ queryKey: "movies" });
//     },
//   });
//   return { mutate, isLoading, data, isError, isSuccess };
// };

// const useEditEpisode = (id) => {
//   const queryClient = useQueryClient();
//   const { mutate, data, isLoading, isError } = useMutation({
//     mutationFn: editEpisode,
//     onSuccess: () => {
//       queryClient.invalidateQueries({ queryKey: ["episodes", id] });
//     },
//   });
//   return { mutate, isLoading, data, isError };
// };

// const useAddMovie = () => {
//   const queryClient = useQueryClient();
//   const { mutate, data, isError, isSuccess, isLoading } = useMutation({
//     mutationFn: addMovie,

//     onSuccess: () => {
//       queryClient.invalidateQueries({ queryKey: "movies" });
//     },
//   });
//   return { mutate, isLoading, data, isError, isSuccess };
// };

// const useAddEpisodeToMovie = () => {
//   const queryClient = useQueryClient();
//   const { mutate, data, isError, isSuccess, isLoading } = useMutation({
//     mutationFn: addEpisodeToMovie,
//     // mutationKey: ['genres' , slug],
//     // onMutate: async (newGenre) => {
//     //   // Cancel any outgoing refetches
//     //   // (so they don't overwrite our optimistic update)
//     //   await queryClient.cancelQueries({ queryKey: "genres" });

//     //   // Snapshot the previous value
//     //   const previousGenres = await queryClient.getQueryData("genres");
//     //   console.log({ previousGenres });
//     //   // Optimistically update to the new value
//     //   queryClient.setQueryData("genres", (old) => [...old, newGenre]);

//     //   // Return a context object with the snapshotted value
//     //   return { previousGenres };
//     // },
//     // // If the mutation fails,
//     // // use the context returned from onMutate to roll back
//     // onError: (err, newGenre, context) => {
//     //   queryClient.setQueryData("genres", context.previousGenres);
//     // },
//     // // Always refetch after error or success:
//     // onSettled: () => {
//     //   queryClient.invalidateQueries({ queryKey:"genres" });
//     // },
//     onSuccess: () => {
//       // Invalidate and refetch
//       queryClient.invalidateQueries({ queryKey: "movies" });
//     },
//   });
//   return { mutate, isLoading, data, isError, isSuccess };
// };
const useWatchHistory = () => {
  const { data, isError, error, isLoading } = useQuery({
    queryKey: "watch-history",
    queryFn: getWatchHistory,
    retry: false,
  });

  return { data, isError, error, isLoading };
};

const useUpdateWatchHistory = () => {
  const queryClient = useQueryClient();
  const { mutate, data, isError, isSuccess, isLoading } = useMutation({
    mutationFn: updateWatchHistory,
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries({ queryKey: "watch-history" });
    },
  });
  return { mutate, data, isError, isSuccess, isLoading };
};
const useHomePageTopSection = () => {
  const { data, isLoading, isError, isSuccess } = useQuery({
    queryFn: getHomePageTopSection,
    queryKey: "homepage-top-section",
  });

  return { data, isLoading, isError, isSuccess };
};

const useSearchMovie = (query) => {
  const { data, isLoading, isError, isSuccess } = useQuery({
    queryFn: () => {
      if (!!query.length) {
        return searchMovie(query);
      }
    },
    // cacheTime: 0
    queryKey: ["searchResults", query],
  });
  return { data, isLoading, isError, isSuccess };
};
export {
  useHomeMovies,
  useEpisodes,
  useWatchHistory,
  useUpdateWatchHistory,
  useHomePageTopSection,
  useSearchMovie,
  // useAddMovie,
  // useAddEpisodeToMovie,
  // useEditMovie,
  // useInfiniteMovies,
  // useEpisodes,
  // useEditEpisode,
  // , useEditGenre,
  //  useAddGenre
};
