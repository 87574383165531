import { useState } from "react";
import contextFactory from "./helpers/contextFactory";

const [GlobalStateContext, useGlobalStateContext] = contextFactory();

export { useGlobalStateContext };

const GlobalStateProvider = ({ children }) => {
  const [state, setState] = useState({});

  return (
    <GlobalStateContext.Provider value={{ state, setState }}>
      {children}
    </GlobalStateContext.Provider>
  );
};

export default GlobalStateProvider;
