import { ShieldWithTickAtCenter } from "@app/components/Icon/icons";

import FaqPrivacyAccountLayout from "@app/layouts/FaqPrivacyAccountLayout";
import React from "react";
import AccountLeft from "./AccountLeft";
import AccountRight from "./AccountRight";

const Account = () => {
  return (
    <FaqPrivacyAccountLayout
      icon={ShieldWithTickAtCenter}
      bannerHeader="My Account"
      // bannerFollowupText="Lorem ipsi donot custo edni"
      left={<AccountLeft/>}
      right={<AccountRight/>}
    />
  );
};

export default Account;
