import * as React from "react";
const SvgCircleRedLeftBlueRight = (props) => (
  <svg
    width={30}
    height={31}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M15.04.305a14.921 14.921 0 0 0 0 29.842V.305Z" fill="#E90101" />
    <path
      d="M15.04 30.146a14.921 14.921 0 0 0 0-29.842v29.842Z"
      fill="#5789DD"
    />
  </svg>
);
export default SvgCircleRedLeftBlueRight;
