// import Logo from "@app/components/common/Logo";
import React from "react";
import Background from "@app/assets/images/waptvbg.png"
// import Card2 from "@app/assets/images/moviecard2.jpg";
// import Card3 from "@app/assets/images/moviecard3.png";

const Layout = ({ children }) => {
  return (
    <section className="w-screen h-screen  relative grid md:flex flex-wrap grid-cols-6  bg-primary-02 overflow-hidden">
      <div className="absolute top-0 left-0 right-0 bottom-0  md:bg-repeat-y bg-authpage-bg bg-cover md:bg-contain bg-center md:bg-center bg-[#1C0000] bg-no-repeat">

        <div className="absolute w-full top-0 left-0 h-full bg-[#1C0000]/70"/> 
        {/* <img src={Background} alt=""  className="absolute top-0 bottom-0 left-0 right-0 object-fit "/> */}
      </div>
      {/* <div className="col-start-1 col-span-1  "> */}
      {/* <img
        src={Background}
        alt=""
        className="col-start-1 col-span-1 row-start-1 row-span-1 h-fit"
      /> */}
     
      <div className="flex flex-col gap-5 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">

        {children}
      </div>
    </section>
  );
};

export default Layout;