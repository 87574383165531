import classNames from "classnames";
import React from "react";

const DummyProfilePicture = ({
  children,
  backgroundType = "blue",
  size = "medium",
  className,
}) => {
  return (
    <div
      className={classNames(
        "rounded-full flex items-center justify-center text-[#fff]",
        {
          "md:w-8 md:h-8 w-10 h-10": size === "small",
          "md:w-10 md:h-10 w-12 h-12": size === "medium",
          "w-14 h-14": size === "large",
          "bg-gradient-red": backgroundType === "red",
          "bg-gradient-blue": backgroundType === "blue",
        },
        className
      )}
    >
      <span className="text-h5 ">{children}</span>
    </div>
  );
};

export default DummyProfilePicture;
