import React, { useEffect} from "react";
// import AuthForm ;
import AuthForm from "@app/views/Authentication/components/AuthForm";
import Input from "@app/components/common/Input";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
  REGEX_ALPHANUMERIC_AT_LEAST_ONE_SYMBOL_AND_UPPERCASE,
  
} from "@app/constants";
import {  useNavigate } from "react-router-dom";
import {  useChangePassword } from "@app/utils/hooks/reactQuery/user";
import { useErrorHandler, useLocalStorage } from "@app/utils/hooks";
import Toast from "@app/components/common/Toast";
import { toast } from "react-toastify";

const INFO = [
  {
    type: "Old Password",
    name: "oldPassword",
  },
  {
    type: "New Password",
    name: "newPassword",
  },
];

const ChangePassword = () => {
  const {
    data,
    isLoading,
    isError,
    mutate,
  } = useErrorHandler(useChangePassword);
  const [, setId] = useLocalStorage("otp_id");

  const formik = useFormik({
    initialValues: {
      oldPassword: "",
      newPassword: "",
    },
    validationSchema: Yup.object({
      newPassword: Yup.string()
        .trim()
        .matches(
          REGEX_ALPHANUMERIC_AT_LEAST_ONE_SYMBOL_AND_UPPERCASE,
          "Email address should be alphanumeric and consist of an Uppercase character and at least a symbol!"
        )
        .required("This field is required!"),
      oldPassword: Yup.string().min(1, "Password field cannot be empty."),
      // password: Yup.string()
      //   .min(8, "Password must be at least 8 characters long!")
      //   .required("This field is required!"),
      // // token: Yup.string().email('Invalid email address').required('Required'),
    }),
    onSubmit: async (values) => {
      try {
        // console.log({ values });
        await mutate(values);
        // setNewEmail(values.email);
      } catch (error) {
        console.log("error in ChangePassword Component: ", error);
      }
      // },
    },
  });
  const navigate = useNavigate();
  useEffect(() => {
    // console.log({ data, isLoading, isError });
    if (data && !isLoading && !isError) {
      toast.success(<Toast toastType={"success"} message="Password changed successfully."/>,{
        toastId: "toast-success-change-password"
      })
      navigate("/account");
    }
  }, [data, isError, isLoading, navigate, setId]);
  return (
    <AuthForm
      handleSubmit={formik.handleSubmit}
      isLoading={isLoading}
      text="Change your password"
      title="Change password"
      // description="An OTP will be sent to the new email provided."
      nonAuth
      // belowButtonText={
      //   <>
      //     {" "}
      //     Don't have an account ?{" "}
      //     <Link to="/auth/signup">
      //       {" "}
      //       <span className="text-primary-06 hover:underline hover:underline-offset-2">
      //         Sign up
      //       </span>
      //     </Link>
      //   </>
      // }
    >
      {INFO.map((item, index) => (
        <Input
          onChange={formik.handleChange}
          value={formik.values[item.name]}
          name={item.name}
          onBlur={formik.handleBlur}
          labelTitle={item.type}
          loading={isLoading}
          placeholder={item.placeholder || "placeholder"}
          showEyeIcon={item.name === "password"}
          variant="transparent"
          key={index}
          message={
            formik.touched[item.name] &&
            formik.errors[item.name] && {
              type: "error",
              value: formik.errors[item.name],
            }
          }
        />
      ))}
      {/* <div className="text-right"> */}
      {/* <input
          id="remember_me"
          type="checkbox"
          name="remember_me"
          checked={formik.values.remember_me}
          className="hidden"
          onChange={formik.handleChange}
        /> */}
      {/* <label
          htmlFor="remember_me"
          name="remember_me"
          className="text-[10px] text-[#fff] cursor-pointer flex items-center gap-2"
        >
          <span className="w-4 h-4">
            {formik.values.remember_me ? <TickedChecked /> : <EmptySquare />}
          </span>{" "}
          <span>Remember Me?</span>
        </label> */}

      {/* <Link to="/auth/forgot">
          <span className="hover:underline hover:underline-offset-2 text-[#fff] text-[10px]">
            Forgot Password?
          </span>
        </Link> */}
      {/* </div> */}
    </AuthForm>
  );
};

export default ChangePassword;
