import * as React from "react";
const SvgVanillaTickMark = (props) => (
  <svg
    width={18}
    height={14}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M6 11.2 1.8 7 .4 8.4 6 14 18 2 16.6.6 6 11.2Z"
      fill="currentColor"
      fillOpacity={0.87}
    />
  </svg>
);
export default SvgVanillaTickMark;
