import classNames from "classnames";
import { useSelect } from "downshift";
import React from "react";
import Skeleton from "../common/Skeleton";
// import { CaretLeft } from "../Icon/icons";
import { CaretDown, CaretUp } from "../Icon/icons";
// const MOVIE_TYPES = [
//   { name: "Full Movie", value: "fullMovie" },
//   { name: "Series", value: "series" },
// ];
// function itemToString(item) {
//   return item ? item.title : "";
// }

const Select = ({
  selectedItem,
  handleSelectedItem,
  items,
  className,
  labeflText,
  ButtonContent,
  labelClassname,
  buttonClass,
  customList,
  isLoading,
  hidden,
  defaultButtonUI = true,
}) => {
  // const [selectedItem, setSelectedItem] = useState(items[0]);
  const {
    isOpen,
    getToggleButtonProps,
    getLabelProps,
    getMenuProps,
    highlightedIndex,
    getItemProps,
  } = useSelect({
    items,
    // itemToString,#060606
    // defaultSelectedItem: items[]
    selectedItem: selectedItem || items[0],
    onSelectedItemChange: ({ selectedItem: newSelectedItem }) =>
      handleSelectedItem(newSelectedItem),
  });

  return isLoading ? (
    <Skeleton height={50} fullWidth />
  ) : (
    <div className={classNames("relative w-full", {
      "hidden": hidden,
    }, className)}>
      <div className="flex flex-col ">
        <label
          {...getLabelProps()}
          className={classNames("text-h2 leading-l7", labelClassname)}
        >
          {labeflText}
        </label>
        <div {...getToggleButtonProps()}>
          {!ButtonContent ? (
            <div
              className={classNames(
                " flex gap-2 justify-between items-center cursor-pointer border-[1px] border-neutral-02 rounded-[4px] outline-secondary-06  focus:border-secondary-06",
                {
                  "px-5 py-4": defaultButtonUI === true,
                  "bg-[#fff] text-[#060606]": isOpen,
                  "bg-transparent text-[#fff]": !isOpen,
                },
                buttonClass
              )}
            >
              <span className=" text-h2 leading-l6">{selectedItem?.name}</span>
              {isOpen ? (
                <span className=" w-[9px]">
                  <CaretUp />
                </span>
              ) : (
                <span className=" w-[9px]">
                  <CaretDown />
                </span>
              )}
            </div>
          ) : (
            <>
              {React.cloneElement(ButtonContent, {
                isOpen,
              })}
            </>
          )}
        </div>
      </div>
      <ul
        {...getMenuProps()}
        className="absolute w-full p-0 bg-[#1B1B1B] text-[#fff] z-40 shadow-md  max-h-80 overflow-y-auto overflow-x-none"
      >
        {isOpen &&
          items.map((item, index) => (
            <div
              key={`${item.value}${index}`}
              {...getItemProps({ item, index })}
            >
              {!customList ? (
                <li
                  className={classNames(
                    highlightedIndex === index && "bg-secondary-05",
                    selectedItem === item && "font-medium",
                    "py-2 px-3 shadow-sm flex flex-col",
                    {
                      "py-3 px-4": defaultButtonUI,
                    }
                  )}
                >
                  <span>{item?.name}</span>
                </li>
              ) : (
                React.cloneElement(customList, {
                  checked: selectedItem?.value === item?.value,

                  label: item?.value,
                  name: item?.name,
                  value: item?.value,
                })
              )}
            </div>
          ))}
      </ul>
    </div>
  );
};

export default Select;
