import * as React from "react";
const SvgShieldWithExclamationAtCenter = (props) => (
  <svg
    viewBox="0 0 76 92"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M38 9.292 67.167 22.25v19.584c0 18.833-12.417 36.208-29.167 41.375C21.25 78.042 8.833 60.667 8.833 41.834V22.25L38 9.292Zm0-9.125L.5 16.834v25c0 23.125 16 44.75 37.5 50 21.5-5.25 37.5-26.875 37.5-50v-25L38 .167Zm-4.167 25h8.334V33.5h-8.334v-8.333Zm0 16.667h8.334v25h-8.334v-25Z"
      fill="#ED2B2B"
    />
  </svg>
);
export default SvgShieldWithExclamationAtCenter;
