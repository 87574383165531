import React from "react";
import OtpInput from "react18-input-otp";

const OTPInput = ({ otpValue, setOtpValue, handleSubmit,otpLength }) => {
  return (
    <OtpInput
      isInputNum
      className="gap-[2px]"
      containerStyle="flex justify-center gap-3"
      inputStyle={
        {
          border: "1px solid #ED2B2B",
          borderRadius: "4px",
          padding: "3px 10px",
          background: "transparent",
          color: "#FFFFFF",
          outline: "0px",
          width: "44px",
          fontSize: "32px",
        }
        // "border-[1px] border-primary-04 focus:border-[2px]  rounded-[4px] p-10"
      }
      numInputs={otpLength}
      value={otpValue}
      onChange={(v) => setOtpValue(v)}
      onSubmit={handleSubmit}
    />
  );
};

export default OTPInput;
