import classNames from "classnames";
import React from "react";

const FaqPrivacyAccountLayout = ({
  icon: Icon,
  bannerHeader,
  bannerFollowupText,
  left,
  right,
}) => {
  return (
    <section className="w-full relative min-h-full md:px-2">
      <div className="bg-secondary-01 text-black-06 relative text-center  p-9 w-full mb-12 md:flex items-center flex-col-reverse ">
        <div className="absolute top-1/2 -translate-y-1/2 md:w-full md:top-0 md:-translate-y-0 md:relative  md:text-center w-[40%] text-left">
          <h2 className="font-bold md:text-h4 text-h5 leading-l4">
            {bannerHeader}
          </h2>
          <p>{bannerFollowupText}</p>
        </div>
        <span className="w-20 h-24 inline-block md:w-14 md:h-16 ">
          {<Icon />}
        </span>
      </div>
      <div className="relative md:space-y-4">
        <div
          className={classNames("text-[#ffffff]md:w-full", {
            " w-[50%] ": right,
            "w-[100%]": !right,
          })}
        >
          {left}
        </div>
        <div className="bg-secondary-01 w-[48%] md:relative md:w-full absolute top-0 right-0">
          {right}
        </div>
      </div>
    </section>
  );
};

export default FaqPrivacyAccountLayout;
