import Skeleton from "@app/components/common/Skeleton";
import classNames from "classnames";
import React from "react";

const MoviesRowSkeleton = ({ moviesNumber = 6}) => {
  return (
    <div className="my-1 ">
      <Skeleton width={300} height={32} />

      <div className="flex gap-4">
        <div
          className={classNames(
            //   "flex items-center gap-3 overflow-x-scroll md:gap-1 md:p-2  ",
            "flex gap-2  overflow-x-scroll scrollbar-hide  overflow-y-auto h-[340px]",
            {
              // "h-70": unFinished,
            }
          )}
        >
          {new Array(moviesNumber).fill("1").map((item,index) => (
            <Skeleton width={230} height={240}  key={index}/>
          ))}
          {/* <Skeleton /> */}
        </div>
      </div>
    </div>
  );
};

export default MoviesRowSkeleton;
