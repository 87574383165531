// const { default: api } = require("./api");
import api from "./api";

const getFavourites = () => api.get("/vod/favourites").then((res) => res.data);
const addToFavourites = (movie) =>
  api.post("/vod/favourites/add", { movieId: movie.id });
const removeFromFavourites = (movieId) =>
  api.post("/vod/favourites/remove", { movieId });

export { getFavourites, addToFavourites, removeFromFavourites };
