import * as React from "react";
const SvgCallSupport = (props) => (
  <svg
    viewBox="0 0 84 76"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M79.5 38.917C79.5 16.042 61.75.5 42 .5 22.458.5 4.5 15.708 4.5 39.167a8.199 8.199 0 0 0-4.167 7.166v8.334C.333 59.25 4.083 63 8.667 63h4.166V37.583C12.833 21.458 25.875 8.417 42 8.417s29.167 13.041 29.167 29.166v29.584H37.833V75.5h33.334c4.583 0 8.333-3.75 8.333-8.333v-5.084c2.458-1.291 4.167-3.833 4.167-6.833v-9.583c0-2.917-1.709-5.459-4.167-6.75Z"
      fill="#ED2B2B"
    />
    <path
      d="M29.5 46.333a4.167 4.167 0 1 0 0-8.333 4.167 4.167 0 0 0 0 8.333ZM54.5 46.333a4.167 4.167 0 1 0 0-8.333 4.167 4.167 0 0 0 0 8.333Z"
      fill="#ED2B2B"
    />
    <path
      d="M67 33.958C65 22.083 54.667 13 42.208 13 29.583 13 16 23.458 17.083 39.875c10.292-4.208 18.042-13.375 20.25-24.542C42.792 26.292 54 33.833 67 33.958Z"
      fill="#ED2B2B"
    />
  </svg>
);
export default SvgCallSupport;
