import {
  // addSubscription,
  // deleteSubscription,
  getAllSubscriptions,
  getUserSubscription,
  initializeCharge,
} from "@app/api/subscriptionapi";

const { useQuery, useMutation } = require("react-query");

// const "genres" = ["genres"];

const useSubscriptions = () => {
  const { isFetching, data, isError, error, isLoading } = useQuery({
    queryKey: "subscriptions",
    queryFn: getAllSubscriptions,
    retry: false,
  });
  return { isFetching, data, isError, error, isLoading };
};

const useUserSubscription = () => {
  const { isFetching, data, isError, error, isLoading } = useQuery({
    queryKey: "user-subscription",
    queryFn: getUserSubscription,
    retry: false,
  });
  return { isFetching, data, isError, error, isLoading };
};

const useInitializeCharge = () => {
  const { isFetching, data, isError, error, isLoading, mutate } = useMutation({
    // queryKey: "initialize",
    mutationFn: initializeCharge,
    retry: false,
  });

  return { isFetching, data, isError, error, isLoading, mutate };
};

export { useSubscriptions, useUserSubscription, useInitializeCharge };
