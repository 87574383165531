import * as React from "react";
const SvgMovies = (props) => (
  <svg
  viewBox="0 0 20 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      
      d="M2 2.47L3.76 6H18V14H2V2.47ZM20 0H16L18 4H15L13 0H11L13 4H10L8 0H6L8 4H5L3 0H2C0.9 0 0.00999999 0.9 0.00999999 2L0 14C0 15.1 0.9 16 2 16H18C19.1 16 20 15.1 20 14V0Z" 
      fill="currentColor"
      // fillOpacity={0.6}
    />
  </svg>
);
export default SvgMovies;
