import { useFormik } from "formik";
import React, { useState } from "react";
import AuthForm from "../components/AuthForm";
import * as Yup from "yup";
import { REGEX_EMAIL } from "@app/constants";
import { useNavigate } from "react-router-dom";
import Input from "@app/components/common/Input";
import { forgotPassword } from "@app/api/authapi";
import Toast from "@app/components/common/Toast";
import { toast } from "react-toastify";
import { storage } from "@app/utils";

const Forgot = () => {
  const [isSendingOTP, setIsSendingOTP] = useState(false);
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .trim()
        .matches(REGEX_EMAIL, "Email address is invalid!")
        .required("Please this field cannot be empty!"),
    }),
    onSubmit: (values) => {
      setIsSendingOTP(true);
      forgotPassword(values)
        .then((res) => {
          if (res.statusCode === 200) {
            toast.success(
              <Toast
                message={`An OTP has been sent to ${values.email}.`}
                toastType="success"
              />
            );
            storage.set("email", values.email);
            storage.set("id", res.data.id);
            storage.set("verifyFor", "reset");
          }
        })
        .then(() => navigate("/auth/verify"))
        .catch((e) => console.log("error in senting otp: ", e))
        .finally(() => setIsSendingOTP(false));
    },
  });
  // const handleSubmit = () => {};
  return (
    <AuthForm
      handleSubmit={formik.handleSubmit}
      isLoading={isSendingOTP}
      text="Continue"
      showSocials={false}
      title={"Trouble loggin in?"}
      description={
        "Enter the email associated with your account and we will send you an OTP."
      }
      // belowButtonText={
      //   <>
      //     {" "}
      //     Don't have an account ?{" "}
      //     <Link to="/auth/signin">
      //       <span className="text-primary-06 hover:underline hover:underline-offset-2">
      //         Sign in
      //       </span>
      //     </Link>
      //   </>
      // }
    >
      <Input
        onChange={formik.handleChange}
        value={formik.values.email}
        name="email"
        onBlur={formik.handleBlur}
        labelTitle="Email"
        loading={isSendingOTP}
        placeholder={"JohnDoe@gmail.com"}
        //   showEyeIcon={item.name === "password"}
        variant="transparent"
        //   key={index}
        message={
          formik.touched.email &&
          formik.errors.email && {
            type: "error",
            value: formik.errors.email,
          }
        }
        //   helperText={item.helperText}
      />
    </AuthForm>
  );
};

export default Forgot;
