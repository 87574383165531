import classNames from "classnames";
import React from "react";
import { useNavigate } from "react-router-dom";

const PushNotifications = ({ isSidebarCollapsed, notifications }) => {
  //   const location = useLocation();
  const navigate = useNavigate();
  // const [isSidebarCollapsed] = useLocalStorage("isSidebarCollapsed");
  // console.log({ isSidebarCollapsed });

  // useEffect(() => {}, [isSidebarCollapsed]);
  // //   ({ location });
  return (
    <div
      className={classNames(
        "absolute top-0 right-0 bottom-0 left-10  bg-[#000]/70 z-50 cursor-pointer px-3",
        {
          //  "left-[24opx] ": !isSidebarCollapsed,
          "left-[200px]": !isSidebarCollapsed,
          "left-0": isSidebarCollapsed,
        }
      )}
      onClick={() => navigate(-1)}
    >
      <ul
        className={classNames(
          " absolute left-8 top-1/2 -translate-y-1/2 w-[380px] h-[500px] bg-[#262626] px-6 py-5 flex flex-col gap-3",
          {
            "left-0": !isSidebarCollapsed,
          }
        )}
      >
        {notifications.map((item, index) => (
          <li className="flex items-center gap-2 justify-center text-[#fff]">
            <div className="bg-primary-01 w-[250px] aspect-video"></div>
            <div>
              {/* < */}
              <p className="text-h1 two-lines">{item.title}</p>
              <p className="text-h1 text-neutral-02">{item.time}</p>
            </div>
          </li>
        ))}
      </ul>
      PushNotifications
    </div>
  );
};

export default PushNotifications;
