import Button from "@app/components/common/Button";
import Input from "@app/components/common/Input";
import DummyProfilePicture from "@app/components/DummyProfilePicture";
import { Airplane } from "@app/components/Icon/icons";
import { sendAnalyticsToFirebase } from "@app/config/firebase.config";
import { COMMENTS_PER_PAGE } from "@app/constants";
import { useErrorHandler } from "@app/utils/hooks";
import {
  useAddComment,
  useInfiniteComments,
} from "@app/utils/hooks/reactQuery/comment";
import React, { useEffect } from "react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import CommentItem from "./CommentItem";

const Comment = ({ user }) => {
  const { movieId } = useParams();
  const {
    mutate,
    isLoading: isSendingComment,
    data: addCommentResponse,
  } = useErrorHandler(useAddComment, movieId);
  const [comment, setComment] = useState("");
  const [comments, setComments] = useState([]);
  const {
    data,
    fetchNextPage,
    hasNextPage,

    isFetchingNextPage,
    isLoading,
  } = useErrorHandler(useInfiniteComments, movieId, COMMENTS_PER_PAGE);

  //   console.log({ data, hasNextPage, isLoading, isFetching });
  useEffect(() => {
    if (data && data?.pages) {
      const pages = data?.pages;
      const comments = pages?.map((page) => page.data).flat(1);
      setComments(comments);
    }
    return () => {
      // second
    };
  }, [data, data?.pages]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (comment.trim() !== "") {
      mutate(comment);
      sendAnalyticsToFirebase("comment_added", { movie_id: movieId });
    }
  };

  useEffect(() => {
    if (addCommentResponse) {
      setComment("");
    }
  }, [addCommentResponse]);

  return (
    <div>
      <form
        onSubmit={handleSubmit}
        className="flex items-center gap-3 justify-center p-4"
      >
        <DummyProfilePicture backgroundType="blue">
          {user?.fullName?.[0]}
        </DummyProfilePicture>
        <Input
          value={comment}
          onChange={(e) => setComment(e.target.value)}
          inputClassName="!bg-transparent !text-[#fff] "
          containerClassName="flex-grow max-w-4xl"
          placeholder="Add comment here"
          loading={isSendingComment}
        />
        <div className=" block min-w-32 h-full">
          <button
            className="flex items-center justify-center "
            type="submit"
            disabled={isSendingComment}
          >
            <span className="text-information text-h4 w-6 h-6 ">
              <Airplane />
            </span>
          </button>
        </div>
      </form>
      {/* {!comments.length && !isFetching && !isFetchingNextPage && (
        <p className="text-center my-2 text-[#fff]"> No comments yet!</p>
      )} */}
      <ul className="space-y-2">
        {comments?.map((comment, index) => (
          <CommentItem comment={comment} key={comment?.id} index={index} />
        ))}
      </ul>

      {!isLoading && (
        <Button
          variant="link"
          type="transparent"
          theme="white"
          onClick={() => fetchNextPage()}
          disabled={!hasNextPage || isFetchingNextPage || isLoading}
          loading={isFetchingNextPage}
          className="mx-auto text-center w-full max-w-xs my-4"
        >
          {!comments.length && "No comments to load"}
          {COMMENTS_PER_PAGE <= comments?.length &&
            (hasNextPage ? "Load more comments..." : "No comments to load")}
        </Button>
      )}

      {/* <Button variant="link" type="transparent" theme="white">
        Load more...
      </Button> */}
    </div>
  );
};

export default Comment;
