import { Search } from "@app/components/Icon/icons";
// import { useGlobalStateContext } from "@app/utils/contexts.js/GlobalStateProvider";
import classNames from "classnames";
import React, {  useState } from "react";
// import { useQuery } from "react-query";
import { useNavigate, useSearchParams } from "react-router-dom";

const SearchBox = ({ classes }) => {
  const [searchQuery, setSearchQuery] = useSearchParams();
  const [input, setInput] = useState(searchQuery.get("search_query"));
  // const { pathname } = useLocation();
  // console.log({ pathname });
  const navigate = useNavigate();
  // const { setState } = useGlobalStateContext();
  const handleChange = (e) => {
    setInput(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!!input.length) {
      navigate(`/movies?search_query=${input}`);
      // setSearchQuery((state) => ({ ...state, search_query: input }));
    }
    if (!input.length) setSearchQuery({});
  };

  // useEffect(() => {
  //   if (!!input.length) {
  //     if (pathname?.includes("movies")) {
  //     }
  //   }
  // }, [input, navigate, pathname, setSearchQuery]);
  return (
    <div
      className={classNames(
        "relative flex flex-wrap items-stretch md:w-full w-[90%]  md:max-w-2xl max-w-[950px] flex-auto md:mx-0  ",
        classes
      )}
    >
      <span className="z-10 h-4 text-neutral-02 absolute bg-transparent rounded text-base items-center justify-center w-4 top-1/2 -translate-y-1/2 ml-4">
        {/* <i className="fas fa-lock"></i> */}
        <Search />
      </span>
      <form onSubmit={handleSubmit} className="w-full">
        <input
          type="text"
          placeholder="Search for movie..."
          className="px-3 py-3 placeholder-neutral-02 text-[#fff] relative bg-[#262626] rounded text-h2 border border-transparent outline-none focus:outline-none focus:ring-0 w-full pl-10 caret-neutral-02 "
          onChange={handleChange}
          value={input}
        />
      </form>
    </div>
  );

  //   return <Input name="search" value={search} onChange={handleChange} placeholder="Movies,Tv Series..." containerClassName="h-full" showSearchIcon/>;
};

export default SearchBox;
