import React from "react";

const ListItem = ({ value, index }) => {
  return (
    <li className="space-y-1">
      <p className="text-[24px] ">
        {index + 1}. {value}
      </p>
    </li>
  );
};

const Terms = [
  "Your membership is valid until you unsubscribe. Note that you must have an access to the internet and must have the app installed in your electronic device, and have used subscribed or used our Payment Methods to access content. Note that special offers would be made for exclusive content and may require a new payment plan. ",
  " You must be at least 13 years to use our service. Users who are below the age of 13 may have to do so under the supervision of an adult.",
  "Our service is for your personal use only. Note that they should never be used for commercial purposes. Subscription to any of our payment methods does not mean ownership.",
  " Our service, including the content library, is regularly updated. We encourage you to use the refresh buttons regularly to stay updated. ",
  "The quality of the display of our service may vary from device to device, and may be affected by a variety of factors, such as your location, the bandwidth available, and speed of your internet connection. Our highest resolution for content is 1080p HD, and the lowest is 480p SD.  ",
  "You are responsible for your activities. We therefore advise you protect your login details to help maintain control over the account and to prevent unauthorized users from having access to your account.",
];

const PrivacyLeft = () => {
  return (
    <div className="text-[#fff] w-4/5 mx-auto">
      <h2 className="text-h4 font-bold mb-8 text-[#fff]"> </h2>
      <p className="text-[26px] md:text-h3">
        WapTV provides a custom-made subscription service that allows our
        members to access its original entertainment content over the Internet
        on computers, tablets and mobile devices upon installation and creating
        of account. These Terms of Use oversee your use of our service. As used
        here "our service" means the service made available by wapTV for
        discovering and accessing our content, including all features on this
        VOD app, our websites, and other interfaces you might be redirected to
        through this app. References to ‘you’ indicate the end user who has
        created an account and or subscribed to our payment feature.
      </p>
      <ol className="space-y-8 py-6 px-3" >
        {Terms.map((item, index) => (
          <ListItem value={item} index={index} />
        ))}
      </ol>
    </div>
  );
};

export default PrivacyLeft;
