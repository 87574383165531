import {
  EmailAddress,
  LocationMarker,
  Telephone,
} from "@app/components/Icon/icons";
// import { updateEmail } from "firebase/auth";
import React from "react";
import Card from "../Account/AccountLeft/components/Card";

// waptv:
// 20, Olutosin Ajayi Street, Ajao Estate, Off Airport Road, Isolo, Lagos.
// Phone number: +234(0)9161858455
// email: waptv@thewaptv.net
// WAP:
// 6, Solo Amahaotu Street, off Eleganza bus-stop, Ajao Estate, Lagos.
// Phone number: +234(0)8070523080
// email:info@waplimited.com

const ICONS = {
  address: <LocationMarker />,
  number: <Telephone />,
  email: <EmailAddress />,
};

const CONTACTS = [
  {
    address:
      "20, Olutosin Ajayi Street, Ajao Estate, Off Airport Road, Isolo, Lagos.",
    number: "+234(0)9161858455",
    email: "waptv@thewaptv.net",
  },
  {
    address:
      "6, Solo Amahaotu Street, off Eleganza bus-stop, Ajao Estate, Lagos",
    number: "+234(0)8070523080",
    email: "info@waplimited.com",
  },
];

const HEADINGS = {
  0: "Waptv",
  1: "WAP",
};
const FaqLeft = () => {
  return (
    <div className="flex gap-2 justify-between md:flex-col ">
      {CONTACTS.map((contact, index) => (
        <>
          <Card heading={HEADINGS[index]}>
            {Object.entries(contact).map((item) => (
              <p className="flex gap-5 items-center my-1">
                <span className="md:w-6 md:h-4 w-5 h-5 text-[#FFF] flex-shrink-0 flex items-center justify-center">
                  {ICONS[item[0]]}
                </span>
                <span className="md:text-h2 text-neutral-01"> {item[1]}</span>
              </p>
            ))}
          </Card>
        </>
      ))}
    </div>
  );
};

export default FaqLeft;
