// import { addAdmin, approveAdmin, getAdmins } from "@app/api/adminapi";
import { changeEmail, changePassword, getUserProfile } from "@app/api/authapi";
// import storage from "@app/utils/storage";

const { useMutation, useQuery } = require("react-query");

// const USERS = "users";

// const useAdmins = () => {
//   const { isFetching, data, isError, error, isLoading } = useQuery({
//     queryKey: ADMINS,
//     queryFn: getAdmins,
//     retry: false,
//   });
//   return { isFetching, data, isError, error, isLoading };
// };

const useChangeEmail = () => {
  // const id = nanoid();
  // const queryClient = useQueryClient();
  const { mutate, data, isError, isSuccess, isLoading, isFetching, error } =
    useMutation({
      mutationFn: changeEmail,
    });
  return { mutate, isLoading, data, isError, isSuccess, isFetching, error };
};
const useChangePassword = () => {
  // const id = nanoid();
  // const queryClient = useQueryClient();
  const { mutate, data, isError, isSuccess, isLoading, isFetching, error } =
    useMutation({
      mutationFn: changePassword,
    });
  return { mutate, isLoading, data, isError, isSuccess, isFetching, error };
};

const useUserProfile = () => {
  const { data, isError, isSuccess, isLoading, isFetching, error } = useQuery({
    queryFn: getUserProfile,
    queryKey: "profile",
  });
  return { data, isError, isSuccess, isLoading, isFetching, error };
};
// const useApproveAdmin = (id) => {
//   const queryClient = useQueryClient();
//   const { mutate, data, isError, isSuccess, isLoading, isFetching, error } =
//     useMutation({
//       mutationFn: (action) => approveAdmin(id, action),
//       // mutationKey: [ADMINS, id],
//       onMutate: async (newGenre) => {
//         // Cancel any outgoing refetches
//         // (so they don't overwrite our optimistic update)
//         await queryClient.cancelQueries({ queryKey: ADMINS });

//         // Snapshot the previous value
//         const previousGenres = queryClient.getQueryData(ADMINS);
//         // Optimistically update to the new value
//         queryClient.setQueryData(ADMINS, (old) => [
//           ...old,
//           { ...newGenre, isVerified: false, status: "awaiting-signup" },
//         ]);

//         // Return a context object with the snapshotted value
//         return { previousGenres };
//       },
//       // If the mutation fails,
//       // use the context returned from onMutate to roll back
//       onError: (err, newGenre, context) => {
//         queryClient.setQueryData(ADMINS, context.previousGenres);
//       },
//       // Always refetch after error or success:
//       onSettled: () => {
//         queryClient.invalidateQueries({ queryKey: ADMINS });
//       },
//       // onSuccess: () => {
//       //   // Invalidate and refetch
//       //   queryClient.invalidateQueries({ queryKey: ['genres'] })
//       // },
//     });
//   return {
//     approveDeleteSuspend: mutate,
//     isLoading,
//     data,
//     isError,
//     isSuccess,
//     isFetching,
//     error,
//   };
// };

export { useChangeEmail, useUserProfile, useChangePassword };
