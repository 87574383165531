import Button from "@app/components/common/Button";
import {
  CircleRedLeftBlueRight,
  CubeWithShadesOfBlue,
  SquareRedLeftBlueTopRightSkyBottomRight,
} from "@app/components/Icon/icons";
import { sendAnalyticsToFirebase } from "@app/config/firebase.config";
import { useErrorHandler } from "@app/utils/hooks";
import {
  useInitializeCharge,
  useUserSubscription,
} from "@app/utils/hooks/reactQuery/subscription";
import classNames from "classnames";
import React, { useEffect } from "react";

const ICONS = {
  circle: CircleRedLeftBlueRight,
  square: SquareRedLeftBlueTopRightSkyBottomRight,
  cube: CubeWithShadesOfBlue,
};

const SubscriptionCard = ({
  iconBackground = "red",
  icon = "circle",
  plan = "Monthly",
  description,
  price,
  subscription,
}) => {
  // ({ subscription });
  const { data: userSubscription } =
    useErrorHandler(useUserSubscription);

  const { mutate, data, isLoading } = useErrorHandler(useInitializeCharge);
  const Icon = ICONS[icon];

  const handleSubscriptionClick = () => {
    mutate({
      channel: "web",
      planId: subscription?.id,
      planCode: subscription?.planCode,
      price: subscription?.price,
    });
    sendAnalyticsToFirebase("subscription_plan_clicked", {
      plan_name: subscription?.title,
      plan_id: subscription?.id,
      price: subscription?.price,
      duration: subscription?.duration,
    });
  };

  useEffect(() => {
    if (data && !isLoading) {
      window.location.href = data?.data?.authorizationUrl;
    }
  }, [data, isLoading]);
  return (
    <div className="bg-[#fff] md:mx-auto w-[350px] p-7 md:rounded-2xl rounded-2xl">
      <div className="flex items-center  gap-3">
        <span
          className={classNames(
            "p-3 inline-block rounded-[12px] bg-primary-01",
            {
              "bg-primary-01": iconBackground === "red",
              "bg-[#fff]": iconBackground === "white",
              "bg-secondary-02": iconBackground === "blue",
            }
          )}
        >
          {}
          {/* <CircleRedLeftBlueRight /> */}
          <Icon />
        </span>
        <div className="flex flex-col gap-[1px] ">
          <p className="text-neutral-05 text-h2 font-semibold">For you</p>
          <p className="text-black-07 text-h3 font-semibold">{plan}</p>
        </div>
      </div>

      <p className="text-h2 mt-4 mb-6">{description}</p>
      <p className="md:text-h5 text-h6 font-semibold ">NGN {price}</p>

      <Button
        className="rounded-full w-full py-[17px] text-h5 "
        buttonTextClassName="font-semibold"
        onClick={handleSubscriptionClick}
        disabled={
          userSubscription?.planCode === subscription?.planCode || isLoading
        }
        loading={isLoading}
      >
        Get Started
      </Button>
    </div>
  );
};

export default SubscriptionCard;
