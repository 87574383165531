import MoviePreviewCard from "@app/components/MoviesRow/MoviePreviewCard";
import MoviesThumbnail from "@app/components/MoviesRow/MoviesThumbnail";
import { useErrorHandler } from "@app/utils/hooks";
import { useWatchList } from "@app/utils/hooks/reactQuery/watchList";
import React from "react";

const WatchList = () => {
  const { data: watchList,
    //  isLoading: isGettingList
     } =
    useErrorHandler(useWatchList);
  return (
    <section>
      <h1 className="page-title">Watch List</h1>
      {
          !watchList?.length && <p className="text-[#fff] text-center">There are no movies in watch list yet!</p>
        }
      <div className="text-[#fff] grid-grid ">
        {watchList?.map((item, idx) => (
          <MoviesThumbnail
            home={false}
            key={item.id}
            // unFinished={unFinished}
            index={item.id}
            MoviePoster={item?.movie?.poster}
            title={item?.movie?.title}
          >
            <MoviePreviewCard movie={{ ...item?.movie, id: item?.id }} />
          </MoviesThumbnail>
        ))}
      </div>
    </section>
  );
};

export default WatchList;
