import * as React from "react";
const SvgCircledX = (props) => (
  <svg
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12 .833A11.656 11.656 0 0 0 .333 12.5 11.656 11.656 0 0 0 12 24.167 11.656 11.656 0 0 0 23.667 12.5 11.656 11.656 0 0 0 12 .833Zm0 21c-5.145 0-9.333-4.188-9.333-9.333 0-5.145 4.188-9.333 9.333-9.333 5.145 0 9.333 4.188 9.333 9.333 0 5.145-4.188 9.333-9.333 9.333Zm4.188-15.166L12 10.855 7.812 6.667 6.167 8.312l4.188 4.188-4.188 4.188 1.645 1.645L12 14.145l4.188 4.188 1.645-1.645-4.188-4.188 4.188-4.188-1.645-1.645Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgCircledX;
