import DummyProfilePicture from "@app/components/DummyProfilePicture/DummyProfilePicture";
import { CaretDown } from "@app/components/Icon/icons";
import React from "react";

const User = ({isCollapsed, name}) => {
    const firstLetter = name?.slice(0,1).toUpperCase()
  return (
    <button className="flex gap-2 items-center">
      <DummyProfilePicture size="small">{firstLetter}</DummyProfilePicture>
      {
        !isCollapsed &&
      <div className="text-[#FFF] flex items-center gap-2">
        <p className="text-sm truncate  w-[120px] max-w-[150px] ">{name}</p>

        <CaretDown />
      </div>
      }
    </button>
  );
};

export default User;
