import React from "react";
import Tippy from "@tippyjs/react/headless"; // different import path!
import { ArrowDown } from "../Icon/icons";

const Tooltip = ({ message, children }) => (
  <Tippy
  
    render={(attrs) => (
      <div
        className="bg-[#D9D9D9] px-4 py-2
       text-[#060606] text-h2 rounded-[5px] relative"
        tabIndex="-1"
        {...attrs}
      >
        {message}
        <div
          className="inline-block w-4 h-3 text-[#D9D9D9] absolute left-1/2 -translate-x-1/2  top-[92%] translate-y-1/2 "
          data-popper-arrow=""
        >
          <ArrowDown />
        </div>
      </div>
    )}
  >
    {children}
  </Tippy>
);

export default Tooltip;
