// const { default: api } = require("./api");
import api from "./api";

const getWatchList = () => api.get("/vod/watchlist").then((res) => res.data);
const addToWatchList = (movie) => api.post("/vod/watchlist/add" , {movieId: movie.id})
const removeFromWatchList = (movieId) => api.post("/vod/watchlist/remove" , {movieId})


export {
    getWatchList,
    addToWatchList,
    removeFromWatchList
}
