import * as React from "react";
const SvgListWithTick = (props) => (
  <svg
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M21.333 3.167v14h-14v-14h14Zm0-2.334h-14A2.34 2.34 0 0 0 5 3.167v14A2.34 2.34 0 0 0 7.333 19.5h14a2.34 2.34 0 0 0 2.334-2.333v-14A2.34 2.34 0 0 0 21.333.833Zm-8.785 14L8.5 10.75l1.633-1.645 2.415 2.427L18.533 5.5l1.634 1.645-7.619 7.688ZM2.667 5.5H.333v16.333a2.34 2.34 0 0 0 2.334 2.334H19v-2.334H2.667V5.5Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgListWithTick;
