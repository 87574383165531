import { resendOTP, verifyUser } from "@app/api/authapi";
import Button from "@app/components/common/Button";
import Toast from "@app/components/common/Toast";
import OTPInput from "@app/components/OTPInput";
import { sendAnalyticsToFirebase } from "@app/config/firebase.config";
import { FORGET_PASSWORD, REGISTER, RESET } from "@app/constants";
import { storage } from "@app/utils";
import { useLocalStorage } from "@app/utils/hooks";
// import { getStorage } from "firebase/storage";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import AuthForm from "../components/AuthForm";
const REQUIRED_OTP_LENGTH = 6;
// const INCOMPLETE_OTP = "OTP entered is not complete";
const description = (email) => (
  <>
    An OTP has been sent to <b>{email}</b>.Please enter the 6-digit code below
  </>
);

const Verify = () => {
  const [otp, setOtp] = useState("");
  const navigate = useNavigate();
  const [email] = useLocalStorage("email");
  const [id] = useLocalStorage("id");
  const [isVerifyingUser, setIsVerifyingUser] = useState(false);
  const [isResendingOTP, setIsResendingOTP] = useState(false);
  const VERIFY_FOR_RESET = storage.get("verifyFor") === RESET;

  const handleSubmit = async (e) => {
    e?.preventDefault();
    if (VERIFY_FOR_RESET) {
      // alert("yo!")

      // store OTP to use in reset passsword page
      storage.set("otp", otp);
      navigate("/auth/reset");

      return;
    }

    if (otp.length === REQUIRED_OTP_LENGTH) {
      setIsVerifyingUser(true);
      // const
      const payload = {
        email: storage.get("email"),
        id: storage.get("id"),
        code: otp,
      };
      verifyUser(payload)
        .then((res) => {
          // console.log({ res });
          if (res.statusCode === 200) {
            toast.success(
              <Toast toastType={"success"} message={res.message} />,
              {
                toastId: "toast-successful-verification",
              }
            );
            if (!VERIFY_FOR_RESET) {
              sendAnalyticsToFirebase("email_verified");
            }
          }
        })
        .then(() => navigate("/auth/signin"))
        .catch((e) => console.log("error in verifying user: ", e))
        .finally(() => setIsVerifyingUser(false));
      // console.log({ res });
    } else {
      // alert("incom")
      toast.error(<Toast message="Incomplete OTP" toastType="error" />, {
        toastId: "toast-incomplete-otp",
      });
    }
  };

  const handleResendOTP = () => {
    setIsResendingOTP(true);
    const RESEND_PURPOSE = VERIFY_FOR_RESET ? FORGET_PASSWORD : REGISTER;
    resendOTP(storage.get("email"), RESEND_PURPOSE)
      .then((res) => {
        toast.success(<Toast message={res.message} toastType="success" />, {
          toastId: "toast-successful-otp-resend",
        });
        storage.set("id", res.data.id);
      })
      .catch((e) => console.log("error while resending OTP: ", e))
      .finally(() => setIsResendingOTP(false));
  };

  useEffect(() => {
    if (!email || !id) {
      navigate("/auth/signup");
    }
  }, [email, id, navigate]);

  return (
    <AuthForm
      handleSubmit={handleSubmit}
      isLoading={isVerifyingUser}
      text={VERIFY_FOR_RESET ? "Continue" : "Confirm"}
      title="Verify Email"
      description={description(email)}
      disableButton={isResendingOTP}
    >
      <div>
        <OTPInput
          otpValue={otp}
          setOtpValue={setOtp}
          handleSubmit={handleSubmit}
          otpLength={REQUIRED_OTP_LENGTH}
        />
      </div>
      <Button
        className="-mb-5 pt-5 text-[#fff] self-center"
        variant="link"
        disabled={isVerifyingUser}
        onClick={handleResendOTP}
        // loading={isResendingOTP}
      >
        {isResendingOTP ? "..." : "Resend OTP"}
      </Button>
    </AuthForm>
  );
};

export default Verify;
