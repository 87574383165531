// import FiltersBanner from "@app/components/FiltersBanner";
import MoviesRow from "@app/components/MoviesRow";
import React from "react";

const Series = () => {
  return (
    <div className="text-[#fff] py-3 px-1  ">
      {/* <FiltersBanner /> */}
      <MoviesRow category="Unfinished Movies" unFinished={true} />
      <MoviesRow />
      <MoviesRow />
      <MoviesRow />
    
    </div>
  );
};

export default Series;
