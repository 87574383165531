import * as React from "react";
const SvgHome = (props) => (
  <svg
    width={18}
    height={19}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.03.59a1.5 1.5 0 0 1 1.94 0l7.5 6.363A1.5 1.5 0 0 1 18 8.097V17.5a1.5 1.5 0 0 1-1.5 1.5h-5.75a.75.75 0 0 1-.75-.75V12H8v6.25a.75.75 0 0 1-.75.75H1.5A1.5 1.5 0 0 1 0 17.5V8.097c0-.44.194-.859.53-1.144L8.03.59ZM9 1.734 1.5 8.097V17.5h5v-6.25a.75.75 0 0 1 .75-.75h3.5a.75.75 0 0 1 .75.75v6.25h5V8.097L9 1.734Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgHome;
