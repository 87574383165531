import MoviesRow from "@app/components/MoviesRow";
import MoviesRowSkeleton from "@app/components/MoviesRowSkeleton";
import { SERIES } from "@app/constants";
import { useErrorHandler } from "@app/utils/hooks";

import {
  useHomeMovies,
  useSearchMovie,
  useWatchHistory,
} from "@app/utils/hooks/reactQuery/movie";
import React, { useMemo, useState } from "react";
import { useEffect } from "react";
import MoviesModal from "./MoviesModal";
import FiltersBanner from "@app/components/FiltersBanner";
// import { useGlobalStateContext } from "@app/utils/contexts.js/GlobalStateProvider";
import { useSearchParams } from "react-router-dom";
import Spinner from "@app/components/Spinner";

export const Movies = () => {
  // const {
  //   state: { search },
  // } = useGlobalStateContext();
  const [searchQuery] = useSearchParams();
    const { data: searchResults, isLoading: isSearchingForMovie } =
    useErrorHandler(useSearchMovie, searchQuery?.get("search_query"));
  // const [updateInterval, setUpdateInterval] = useState(null);
  // const
  const [selectedGenres, setSelectedGenres] = useState([]);
  const [unFinished, setUnFinished] = useState([]);
  const { data: watchHistory, isLoading: isLoadingWatchHistory } =
    useErrorHandler(useWatchHistory);
  const { data, isLoading } = useErrorHandler(useHomeMovies);
  
  const GENRES = useMemo(
    () =>
      data?.map((item) => {
        return {
          name: item.category,
        };
      }) || [],
    [data]
  );

  // useEffect(() => setSelectedGenres(GENRES), [GENRES]);
  const DATA_TO_SHOW = useMemo(() => {
    if (!!selectedGenres.length) {
      return data?.filter((item) =>
        selectedGenres?.some((itm) => item.category === itm.name)
      );
    }
    return data;
  }, [data, selectedGenres]);
  const [showModal, setShowModal] = useState(false);
  const [movieToWatch, setMovieToWatch] = useState(false);
  
  const handleUnFinishedMovieClick = (movie) => {
    // console.log({ movie });
    setShowModal(true);
    setMovieToWatch(movie);
  };
  useEffect(() => {
    if (!isLoadingWatchHistory && watchHistory) {
      setUnFinished((unFinished) => []);
      watchHistory.forEach((item) => {
        if (item.movie.type !== SERIES) {
          const movie = item?.movie;
          movie.progress = item?.lastWatched?.progress;
          movie.movieLength = item?.lastWatched?.duration;
          movie.lastWatchedDate = item?.lastWatched?.lastWatchedDate;
          setUnFinished((unFinished) => [...unFinished, movie]);
        } else {
          const movie = item?.movie;
          const itm = item;
          const episodes = item.episodeProgress.map((item) => ({
            ...item,
            title: `${movie.title}[season_${item.season}_episode_${item.episodeNumber}]`,
            movieTitle: movie.title,
            id: `${item.episodeId}`,
            movieLength: item.duration,
            movieUrl: item.videoUrl,
            previewUrl: item.previewUrl,
            lastWatched: itm?.lastWatched,
            movie,
          }));
          setUnFinished((unFinished) => [...unFinished, ...episodes]);
        }
      });
    }
  }, [isLoadingWatchHistory, watchHistory]);

  useEffect(() => {
    // if (search) {e
    console.log({ searchQuery });
    // }
  }, [searchQuery]);

  if (!!searchQuery.get("search_query")) {
    return isSearchingForMovie ? (
      <p className="text-[#fff] text-center my-10">
        <Spinner size="md" show={isSearchingForMovie} />
      </p>
    ) : (
      <p className="text-[#fff] ">
        {" "}
        <MoviesRow
          category={
            !!searchResults?.length
              ? `Search Results for ${searchQuery.get("search_query")}`
              : "No movies found"
          }
          movies={searchResults}
        />
      </p>
    );
  }

  return (
    <div>
      <FiltersBanner
        genres={GENRES}
        setSelectedGenres={setSelectedGenres}
        selectedGenres={selectedGenres}
      />
      {!selectedGenres.length && (
        <div className="text-[#fff]">
          {isLoadingWatchHistory
            ? new Array(1).fill("").map(() => <MoviesRowSkeleton />)
            : !!unFinished.length && (
                <MoviesRow
                  category="Unfinished videos"
                  movies={unFinished}
                  unFinished
                  handleCardClick={handleUnFinishedMovieClick}
                />
              )}
        </div>
      )}
      <div className="my-8 text-[#fff]">
        {isLoading
          ? new Array(10).fill("").map(() => <MoviesRowSkeleton />)
          : DATA_TO_SHOW?.map((item) => (
              <MoviesRow category={item?.category} movies={item?.data} />
            ))}
      </div>
      <MoviesModal
        showModal={showModal}
        setShowModal={setShowModal}
        movieToWatch={movieToWatch}
        setMovieToWatch={setMovieToWatch}
      />
    </div>
  );
};
export default Movies;
