import { SuccessMark } from "@app/components/Icon/icons";
import React from "react";
import { useNavigate } from "react-router-dom";
import AuthForm from "../components/AuthForm";

const SuccessfulReset = () => {
  const navigate = useNavigate();
  return (
    <AuthForm
      handleSubmit={() => navigate("/auth/signin")}
      icon={SuccessMark}
      title="Password Reset"
      text="Login"
      description=" Your password has been reset succesfully."
      showSocials={false}
      isReset
    />
  );
};

export default SuccessfulReset;
