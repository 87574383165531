import * as React from "react";
const SvgCubeWithShadesOfBlue = (props) => (
  <svg
    width={34}
    height={36}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="m33.928 26.588.007-.004h-.014l-8.345-4.522-8.36-4.53-8.358 4.53-8.345 4.522H.498l.008.004-.008.004h.015l8.345 4.522 8.359 4.53 8.359-4.53 8.345-4.522h.014l-.007-.004Z"
      fill="#C7D8F4"
    />
    <path
      d="m33.928 9.22.007-.003h-.014l-8.345-4.522-8.36-4.53-8.358 4.53L.513 9.217H.499l.007.004-.007.004h.014l8.345 4.522 8.359 4.53 8.359-4.53 8.345-4.522h.014l-.007-.004Z"
      fill="#5789DD"
    />
    <path
      d="M.513 8.852v18.103l16.704-9.051L.513 8.852ZM33.92 8.852v18.103l-16.703-9.051L33.92 8.852Z"
      fill="#8FB0E8"
    />
  </svg>
);
export default SvgCubeWithShadesOfBlue;
