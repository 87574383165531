import * as React from "react";
const SvgHamburger = (props) => (
  <svg
    viewBox="0 0 20 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M0 10v2h20v-2H0Zm0-5v2h20V5H0Zm0-5v2h20V0H0Z"
      fill="currentColor"
      fillOpacity={0.87}
    />
  </svg>
);
export default SvgHamburger;
