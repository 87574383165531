import {
  addToWatchList,
  getWatchList,
  removeFromWatchList,
} from "@app/api/watchListapi";

const { useQuery, useMutation, useQueryClient } = require("react-query");

// const "watchlist" = ["watchlist"];

const useWatchList = () => {
  const { isFetching, data, isError, error, isLoading } = useQuery({
    queryKey: "watchlist",
    queryFn: getWatchList,
    retry: false,
  });
  return { isFetching, data, isError, error, isLoading };
};

const useAddWatchList = (movies) => {
  const queryClient = useQueryClient();
  const { mutate, data, isError, isSuccess, isLoading } = useMutation({
    mutationFn: addToWatchList,
    // mutationKey: ['watchlist' , slug],
    onMutate: async (movie) => {
      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({ queryKey: "watchlist" });

      // Snapshot the previous value
      const previousWatchList = await queryClient.getQueryData("watchlist");
      //   console.log({ previousWatchList });
      // Optimistically update to the new value
      queryClient.setQueryData("watchlist", (old) => {
        // const flattenedMoviesArray = movies.map((item) => item.data).flat(1);
        // const movieToAddToWatchList = flattenedMoviesArray.find(
        //   (movie) => movieId === movie.id
        // );
        return [...old, movie];
      });

      // Return a context object with the snapshotted value
      return { previousWatchList };
    },
    // If the mutation fails,
    // use the context returned from onMutate to roll back
    onError: (err, newFavorite, context) => {
      queryClient.setQueryData("watchlist", context.previousWatchList);
    },
    // Always refetch after error or success:
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: "watchlist" });
    },
    // onSuccess: () => {
    //   // Invalidate and refetch
    //   queryClient.invalidateQueries({ queryKey: "watchlist" });
    // },
  });
  return { mutate, isLoading, data, isError, isSuccess };
};

const useRemoveWatchList = (movies) => {
  const queryClient = useQueryClient();
  const { mutate, data, isError, isSuccess, isLoading } = useMutation({
    mutationFn: removeFromWatchList,
    // mutationKey: ['watchlist' , slug],
    onMutate: async (movieId) => {
      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({ queryKey: "watchlist" });

      // Snapshot the previous value
      const previousWatchList = await queryClient.getQueryData("watchlist");
      //   console.log({ previousWatchList });
      // Optimistically update to the new value
      queryClient.setQueryData("watchlist", (old) => {
        return old.filter((item) => item.id !== movieId);
      });

      // Return a context object with the snapshotted value
      return { previousWatchList };
    },
    // If the mutation fails,
    // use the context returned from onMutate to roll back
    onError: (err, newFavorite, context) => {
      queryClient.setQueryData("watchlist", context.previousWatchList);
    },
    // Always refetch after error or success:
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: "watchlist" });
    },
    // onSuccess: () => {
    //   // Invalidate and refetch
    //   queryClient.invalidateQueries({ queryKey: "watchlist" });
    // },
  });
  return { mutate, isLoading, data, isError, isSuccess };
};

export { useWatchList, useRemoveWatchList, useAddWatchList };
