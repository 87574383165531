import React from "react";
import MoviesRow from "@app/components/MoviesRow";
import {
  useHomeMovies,
  useHomePageTopSection,
} from "@app/utils/hooks/reactQuery/movie";
import { useErrorHandler } from "@app/utils/hooks";
import MoviesRowSkeleton from "@app/components/MoviesRowSkeleton";
import Button from "@app/components/common/Button";
import { PlayInCircle } from "@app/components/Icon/icons";
import { Slider } from "infinite-react-carousel/lib";
import classNames from "classnames";
import { MOVIE } from "@app/constants";
import { useLocation, useNavigate } from "react-router-dom";

const Home = () => {
  const navigate = useNavigate();
  const { data, isLoading } = useErrorHandler(useHomeMovies);
  const { pathname } = useLocation();
  const { data: HomePageTopItems, isLoading: isLoadingHomePageTopSection } =
    useErrorHandler(useHomePageTopSection);
  // console.log({ HomePageTopItems });
  return (
    <div className="text-[#fff] py-3 px-1  ">
      <div
        className={classNames(
          "w-full md:aspect-[9/16] aspect-[10/4] bg-[#262626] relative flex items-end",
          {
            "animate-pulse": isLoadingHomePageTopSection,
          }
        )}
      >
        {HomePageTopItems?.length && (
          // documentation for this : https://www.npmjs.com/package/infinite-react-carousel
          //demo: https://g787543.github.io/infinite-react-carousel/

          <Slider
            autoplay
            className="w-full h-full relative  z-20"
            arrow={false}
            arrowsBlock={false}
            // dots?
            // dotsClass="bg-primary-06 inline-block rounded-full inline-flex "
          >
            {HomePageTopItems?.map((item, index) => (
              <div className="md:block  !flex flex-col justify-end md:aspect-[9/16] aspect-[10/4] bg-neutral-04 relative  w-full">
                <div className="absolute top-0 bottom-0 left-0 right-0 from-transparent to-[#000] via-[#000]/10 bg-gradient-to-b  z-30 flex flex-col justify-end"></div>
                <img
                  src={item.imageUrl}
                  className="relative w-full h-full object-cover"
                  alt={item.title}
                />
                <div className="absolute !z-50 ">
                  <h2 className="font-semibold text-h5">{item?.title}</h2>
                  <p className="w-4/6 my-4 four-lines">{item?.description}</p>

                  {item?.type === MOVIE && (
                    <Button
                      iconPosition="left"
                      icon={PlayInCircle}
                      onClick={() =>
                        // navigate(`/movie/${item?.movieId}`)
                        navigate(
                          pathname === "/"
                            ? `/home/${item.movieId}`
                            : `${item.movieId}`
                        )
                      }
                    >
                      Watch Movie
                    </Button>
                  )}
                </div>
              </div>
            ))}
          </Slider>
        )}
      </div>

      <div className="my-8">
        {isLoading
          ? new Array(10).fill("").map(() => <MoviesRowSkeleton />)
          : data?.map((item) => (
              <MoviesRow category={item?.category} movies={item?.data} />
            ))}
      </div>
      {/* <MoviesRow category={data?.category} unFinished={true} />
      <MoviesRow />
      <MoviesRow />
      <MoviesRow /> */}
    </div>
  );
};

export default Home;
