import * as React from "react";
const SvgClockArrow = (props) => (
  <svg
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8 5v5l4.25 2.52.77-1.28L9.5 9.15V5H8Zm10 2V0l-2.64 2.64A8.937 8.937 0 0 0 9 0a9 9 0 1 0 9 9h-2c0 3.86-3.14 7-7 7s-7-3.14-7-7 3.14-7 7-7c1.93 0 3.68.79 4.95 2.05L11 7h7Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgClockArrow;
