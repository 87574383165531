import { useErrorHandler } from "@app/utils/hooks";
import React, { useEffect, useMemo } from "react";

import { useEpisodes } from "@app/utils/hooks/reactQuery/movie";
import classNames from "classnames";

const EpisodesReel = ({
  movieToWatch,
  setMovieToWatch,
  movie,
  setNextEpisode,
}) => {
  const { data } = useErrorHandler(useEpisodes, movie.id);
  const EPISODES = useMemo(
    () =>
      data?.data[movieToWatch?.season].sort(
        (a, b) => a.episodeNumber - b.episodeNumber
      ),
    [data?.data, movieToWatch?.season]
  );
  console.log({ movieToWatch, EPISODES });

  useEffect(() => {
    const currentEpisodeIndex = EPISODES?.findIndex(
      (item) => item?.id === movieToWatch?.id
    );
    if (EPISODES?.[currentEpisodeIndex + 1]) {
      setNextEpisode(EPISODES[currentEpisodeIndex + 1]);
    }
  }, [EPISODES, movieToWatch?.id, setNextEpisode]);
  const handleEpisodeClick = (item) => {
    setMovieToWatch({ ...item, movie });
  };
  return (
    <div className="my-10 md:overflow-scroll ">
      <p className="text-center md:text-h2 text-h3 font-semibold text-[#fff]">{`Season ${movieToWatch?.season}`}</p>
      <ul className="flex gap-2 px-2 justify-center">
        {EPISODES?.map((item) => {
          return (
            <li
              className={classNames("cursor-pointer text-[#fff]", {
                "border-2 border-primary-03 p-2": item.id === movieToWatch?.id,
              })}
              onClick={() => handleEpisodeClick(item)}
            >
              <div className="w-24 aspect-video bg-secondary-05">
                <img
                  src={item?.poster}
                  alt={item?.title}
                  className="w-full h-full cover"
                />
              </div>
              <p className="text-h1">
                <span className="font-semibold">Episode Number:</span>{" "}
                <span>{item.episodeNumber}</span>
              </p>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default EpisodesReel;
