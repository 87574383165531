const { default: api } = require("./api");

const getComments = (movieId, limit, lastCommentId ) =>
  api
    .get(
      `/vod/movie-comments?pageLimit=${limit}&movieId=${movieId}${
        !!lastCommentId?.length ? `&lastCommentId=${lastCommentId}` : ""
      }`
    )
    .then((res) => res);
const postComment = (movieId, comment) =>
  api.post("/vod/comment/add", { movieId, comment });

export { getComments, postComment };
