import React, { useCallback, useMemo } from "react";
// import { baseUrl } from "../constants/movie";
// import { Movie } from "../types";
// import Image from "next/image";
// import { useRecoilState } from "recoil";
// import { modalState, movieState } from "../atom/modalAtom";
// import MoviePoster from "@app/assets/images/moviecard2.jpg";
import classNames from "classnames";
import MovieProgress from "./MovieProgress";
import { useLocation, useNavigate } from "react-router-dom";
import useWindowSize from "@app/utils/hooks/useWindowSize";
import { MAX_MOBILE_WIDTH } from "@app/constants";
import { sendAnalyticsToFirebase } from "@app/config/firebase.config";
import dayjs from "dayjs";
// import { Home } from "@app/views";
// overflow-hidden  h-64
const MoviesThumbnail = ({
  index,
  unFinished,
  children,
  MoviePoster,
  title,
  movie,
  home = true,
  handleCardClick,
}) => {
  const navigate = useNavigate();
  const { width } = useWindowSize();
  const { pathname } = useLocation();
  const handleThumbnailClick = useCallback(() => {
    if (width > MAX_MOBILE_WIDTH) {
      navigate(pathname === "/" ? `/home/${index}` : `${index}`);
    }
  }, [index, navigate, pathname, width]);
  //   const [showModal, setShowModal] = useRecoilState(modalState);
  //   const [currentMovie, setCurrentMovie] = useRecoilState(movieState);
  //   aspasp ect-[240/290]
  // if (movie?.title === "This is a test movie" && unFinished) {
  //   console.log({ movie });
  //   console.log({ duration: movie?.duration, progress: movie?.progress });
  // }
  const handleThumbnailPlayClick = useMemo(() => {
    // console.log({ movie });
    if (unFinished) {
      // if (movie?.type === FULLMOVIE) {
      const parameter = {
        movie_id: movie?.id,
        movie_tile: movie?.title,
        isPremium: movie?.isPremium,
        last_watched_episode: movie?.lastWatched?.episodeId || "",
        last_watched_date: dayjs(
          movie?.lastWatchedDate?._seconds * 1000
        ).format("DD/MM/YYYY"),
      };
      sendAnalyticsToFirebase("unfinished_movie_tapped", parameter);
      // } else {
      // }
    } else {
      const parameter = {
        movie_id: movie?.id,
        movie_title: movie?.title,
        category: movie?.genres?.join(";"),
        isPremium: movie?.isPremium,
      };

      sendAnalyticsToFirebase("movie_card_tapped", parameter);
    }
    return unFinished ? () => handleCardClick(movie) : handleThumbnailClick;
  }, [handleCardClick, handleThumbnailClick, movie, unFinished]);
  return (
    // <Link to={`${index}`}>
    <div
      id="thumbnail"
      //   className={classNames("py-1 flex flex-col gap-1 group relative")}
      className={classNames(
        "relative group flex flex-col md:h-[180px] aspect-[3/4] bg-[#262626] hover:bg-transparent",
        {
          "h-[200px]": home,
          "h-[220px]": unFinished,
        }
      )}
      onClick={handleThumbnailPlayClick}
      //   className={classNames("py-1 flex flex-col gap-1 group relative")}
      //   onClick={() => {
      //     setCurrentMovie(movie);
      //     setShowModal(true);
      //   }}
    >
      {/* <div className="relative md:w-[150px] w-48 aspect-[100/120] object-cover overflow-hidden "> */}
      <div className="relative md:w-[150px] flex items-center justify-center w-full h-full md:aspect-[3/4] object-cover overflow-hidden max-w-max ">
        <img src={MoviePoster} className="text-center" alt={title} />
      </div>
      {unFinished && (
        <div className="mt-3">
          <MovieProgress
            progress={movie?.progress}
            duration={movie?.movieLength}
          />
        </div>
      )}
      <div className="absolute z-20 top-0 hidden h-[120%] group-hover:block ">
        {React.cloneElement(children, {
          unFinished,
          handleThumbnailPlayClick,
        })}
      </div>
    </div>
    // </Link>
  );
};

export default MoviesThumbnail;
