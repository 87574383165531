const { default: api } = require("./api");

const getHomeMovies = () => api.get("/vod/home-videos").then((res) => res.data);
const getMovieDetails = (id) =>
  api.get(`/vod/movie-details?movieId=${id}`).then((res) => res.data);
const getWatchHistory = () =>
  api.get("/vod/watch-history").then((res) => res.data);
const updateWatchHistory = (payload) =>
  api.post("/vod/update-watch-history", payload).then((res) => res.data);

const getHomePageTopSection = () =>
  api.get("vod/home-page-top-section").then((res) => res.data);

const searchMovie = (query) =>
  api.get(`vod/search?query=${query}`).then((res) => res.data);
export {
  getHomeMovies,
  getMovieDetails,
  getWatchHistory,
  updateWatchHistory,
  getHomePageTopSection,
  searchMovie,
};
