import * as React from "react";
const SvgSuccessMark = (props) => (
  <svg
    viewBox="0 0 101 100"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M96.98 49.998a14.575 14.575 0 0 0 3.021-13.264 14.575 14.575 0 0 0-9.248-9.977A14.572 14.572 0 0 0 73.738 9.745a14.566 14.566 0 0 0-23.24-6.226 14.57 14.57 0 0 0-23.24 6.228 14.571 14.571 0 0 0-17.011 17.015 14.57 14.57 0 0 0-6.228 23.24 14.568 14.568 0 0 0 6.23 23.24 14.566 14.566 0 0 0 17.013 17.011 14.57 14.57 0 0 0 23.24 6.228 14.568 14.568 0 0 0 23.24-6.23 14.568 14.568 0 0 0 17.013-17.013 14.568 14.568 0 0 0 6.226-23.24Z"
      fill="#ED2B2B"
    />
    <path
      d="M41.525 69.084 27.63 55.198a2.817 2.817 0 0 1 0-3.98l1.688-1.69a2.817 2.817 0 0 1 3.98 0L43.4 59.62l23.787-25.277a2.817 2.817 0 0 1 3.978-.122l1.734 1.636a2.817 2.817 0 0 1 .12 3.981L45.573 69.021a2.818 2.818 0 0 1-4.047.063Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgSuccessMark;
