import * as React from "react";
const SvgArrowDown = (props) => (
  <svg
    viewBox="0 0 26 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M15.357 15.865a3 3 0 0 1-4.472 0l-9.39-10.5c-1.728-1.932-.356-5 2.237-5H22.51c2.593 0 3.965 3.068 2.236 5l-9.389 10.5Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgArrowDown;
