import { CaretDown, CaretUp } from "@app/components/Icon/icons";
import classNames from "classnames";
import React from "react";
import Button from "../Button";

const DropdownButton = ({
  isOpen,
  btnClass,
  isVisible = true,
  iconPosition = "left",
  icon: Icon,
  index,
  btnText,
  isCaretIcon,
  ...props
}) => {
  return (
    <Button
      className={classNames(
        "leading-l7 relative hover:text-[#060606] hover:bg-[#fff] ",
        {
          "bg-[#fff] text-[#060606]": isOpen,
          "bg-transparent": !isOpen,
        },
        btnClass
      )}
      variant="transparent"
      theme="white"
      iconPosition={iconPosition}
      icon={isCaretIcon ? (isOpen ? CaretUp : CaretDown) : Icon}
      id={index}
      isCaretIcon={isCaretIcon}
      {...props}
    >
      {btnText}
    </Button>
  );
};

export default DropdownButton;
