// import classNames from "classnames";
import classNames from "classnames";
import React from "react";
import { VanillaTickMark } from "../Icon/icons";
import MultipleSelectFactory from "../MultipleSelectFactory";

const MultipleSelect = ({
  items,
  buttonClass,
  ButtonContent,
  defaultButtonUI,
  onChange,
  itemToString,
  selectedItems,
  setSelectedItems,
}) => {
  return (
    <MultipleSelectFactory
      onChange={onChange}
      itemToString={itemToString}
    >
      {({
        getMenuProps,
        // note that the getRemoveButtonProps prop getter and the removeItem
        // action are coming from MultiDownshift composibility for the win!


        isOpen,
        // below parameter was commented out so I am in control of what's fucking selected
        selectedItems,
        getItemProps,
        highlightedIndex,
        toggleMenu,
      }) => {
        // console.log({ seeeleee: selectedItems });
        return (
          <div className="relative">
            <div>
              {/* {!ButtonContent ? (
            <div
              className={classNames(
                " flex gap-2 justify-between items-center cursor-pointer border-[1px] border-neutral-02 rounded-[4px] outline-secondary-06  focus:border-secondary-06",
                {
                  "px-5 py-4": defaultButtonUI === true,
                  "bg-[#fff] text-[#060606]": isOpen,
                  "bg-transparent text-[#fff]": !isOpen,
                },
                buttonClass
              )}

            >
              <span className=" text-h2 leading-l6">{selectedItem?.name}</span>
              {isOpen ? (
                <span className=" w-[9px]">
                  <CaretUp />
                </span>
              ) : (
                <span className=" w-[9px]">
                  <CaretDown />
                </span>
              )}
            </div>
          ) : ( */}
              <>
                {React.cloneElement(ButtonContent, {
                  isOpen,
                  onClick: () => toggleMenu(),
                })}
              </>
              {/* )} */}
            </div>
            <ul
              {...getMenuProps({ isOpen })}
              className="absolute w-full p-0 bg-[#1B1B1B] text-[#fff] z-40 shadow-md  max-h-80 overflow-y-auto overflow-x-none left-0 top-full"
            >
              {isOpen
                ? items.map((item, index) => {
                    const isSelected = selectedItems.some(
                      (itm) => itm.name === item.name
                    );
                    return (
                      <li
                        key={item.id}
                        {...getItemProps({
                          item,
                          index,
                          isActive: highlightedIndex === index,
                          isSelected,
                          // isSelected: selectedItems.includes(item),
                        })}
                        className={classNames(
                          " cursor-pointer flex items-center gap-1 p-2 text-black-01",
                          {
                            " text-secondary-05": isSelected,
                            // " text-secondary-05": selectedItems.includes(item),
                            " hover:bg-secondary-05": !isSelected,
                          }
                        )}
                      >
                        <span className="w-6 h-6 text-[#fff] inline-flex items-center">
                          <VanillaTickMark
                            className={classNames({
                              // hidden: selectedItems.includes(item),
                              "text-black-02": !isSelected,
                              " text-secondary-05": isSelected,
                            })}
                          />
                        </span>

                        <span className="inline-block text-inherit">
                          {item.name}
                        </span>
                      </li>
                    );
                  })
                : null}
            </ul>
          </div>
        );
      }}
    </MultipleSelectFactory>
  );
};

export default MultipleSelect;
