import { ShieldWithExclamationAtCenter } from "@app/components/Icon/icons";
import FaqPrivacyAccountLayout from "@app/layouts/FaqPrivacyAccountLayout";
import React from "react";
import PrivacyLeft from "./PrivacyLeft";
// import PrivacyRight from "./PrivacyRight";

const Privacy = () => {
  return (
    <FaqPrivacyAccountLayout
      bannerHeader="Privacy Policy"
      left={<PrivacyLeft />}
      // right={<PrivacyRight />}
      icon={ShieldWithExclamationAtCenter}
    />
  );
};

export default Privacy;
