import * as React from "react";
const SvgListWithPlus = (props) => (
  <svg
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M2.667 5.547H.333V21.88a2.34 2.34 0 0 0 2.334 2.334H19V21.88H2.667V5.547ZM21.333.88h-14A2.34 2.34 0 0 0 5 3.214v14a2.34 2.34 0 0 0 2.333 2.333h14a2.34 2.34 0 0 0 2.334-2.333v-14A2.34 2.34 0 0 0 21.333.88Zm0 16.334h-14v-14h14v14Zm-8.166-2.334H15.5v-3.5H19V9.047h-3.5v-3.5h-2.333v3.5h-3.5v2.333h3.5v3.5Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgListWithPlus;
