import api from "./api";
const loginUser = (user) =>
  api.post("/account/login", user).then((res) => res.data);
const createUser = (admin) =>
  api.post("/account/signup", admin).then((res) => res);
const verifyUser = (payload) =>
  api.post("/account/email-verification", payload);
const resendOTP = (email, type) =>
  api.post("/account/resend-otp", {
    email,
    type,
  });

//payload: {email , id , code, password}
const resetPassword = (payload) => api.post("/account/reset-password", payload);

//payload: {email}
const forgotPassword = (payload) =>
  api.post("/account/forget-password", payload);
const getUserProfile = () => api.get("/account/profile").then(res => res.data);

const changeEmail = (email) =>
  api
    .post("/account/change-email", { newEmail: email })
    .then((res) => res.data);

const changePassword = (payload) =>
  api.post("/account/change-password", payload);

const confirmChangeEmail = (payload) =>
  api.post("/account/confirm-change-email", payload);
// payload
// {
//   "email":"odetechit@gmail.com"
// }
// const forgotPassword = (payload) => api.post("/forgot-password", payload);

export {
  createUser,
  loginUser,
  verifyUser,
  resendOTP,
  resetPassword,
  forgotPassword,
  getUserProfile,
  changeEmail,
  confirmChangeEmail,
  changePassword,
};
