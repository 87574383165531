import Button from "@app/components/common/Button";
import { PlayInCircle } from "@app/components/Icon/icons";
import Select from "@app/components/Select";
import { sendAnalyticsToFirebase } from "@app/config/firebase.config";
import { NO_ACTIVE_SUBSCRIPTION, SERIES } from "@app/constants";
import { useAuthContext } from "@app/utils/contexts.js/AuthProvider";
import { useErrorHandler } from "@app/utils/hooks";
import {
  useEpisodes,
  useHomeMovies,
  // useWatchHistory,
} from "@app/utils/hooks/reactQuery/movie";
import { useUserSubscription } from "@app/utils/hooks/reactQuery/subscription";

import React, { useState } from "react";
import { useEffect } from "react";
import { useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import MoviesModal from "../Movies/MoviesModal";
import Comment from "./components/Comment";
import EpisodeList from "./EpisodeList";
// import EpisodeThumbnail from "./EpisodeThumbnail";

const Movie = () => {
  const [movieToWatch, setMovieToWatch] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const { movieId } = useParams();
  const { data } = useErrorHandler(useHomeMovies);
  const navigate = useNavigate();
  const [seasonsObject, setSeasonsObject] = useState({});
  const [seasons, setSeasons] = useState([]);
  const [selectedSeason, setSelectedSeason] = useState(null);
  const { data: userSubscription } = useErrorHandler(useUserSubscription);

  const {
    data: MOVIE_DETAILS,
    // error,
    isLoading,
  } = useErrorHandler(
    useEpisodes,
    movieId
    // movieToEdit?.id || movieId
  );

  // console.log({ MOVIE_DETAILS });

  const MOVIE = useMemo(
    () =>
      data
        ?.map((item) => item.data)
        ?.flat(1)
        ?.find((item) => item.id === movieId),
    [data, movieId]
  );

  useEffect(() => {
    if (MOVIE_DETAILS && MOVIE_DETAILS?.data) {
      // alert("inside");
      const seasonsWithSortedOutEpisodes = {};
      const SEASONS = Object.keys(MOVIE_DETAILS.data).sort((a, b) => a - b);
      const TRANSFORMED_SEASONS = SEASONS.map((season) => ({
        name: `Season ${season}`,
        value: season,
      }));

      setSelectedSeason(TRANSFORMED_SEASONS[0]);
      setSeasons(TRANSFORMED_SEASONS);
      // console.log({ SEASONS });
      SEASONS?.forEach(
        (item) =>
          (seasonsWithSortedOutEpisodes[item] = MOVIE_DETAILS.data[item].sort(
            (firstEpisode, secondEpisode) =>
              firstEpisode?.episodeNumber - secondEpisode?.episodeNumber
          ))
      );
      setSeasonsObject(seasonsWithSortedOutEpisodes);
    }

    return () => {
      // second
    };
  }, [MOVIE_DETAILS, data?.data]);

  useEffect(() => {
    if (data) {
      if (!MOVIE) navigate(-1);
    }
  }, [MOVIE, data, navigate]);
  // console.log({ MOVIE });

  useEffect(() => {
    sendAnalyticsToFirebase("movie_details_viewed", {
      movie_id: MOVIE?.id,
      movie_title: MOVIE?.title,
      movie_type: MOVIE?.type,
      isPremium: MOVIE?.isPremium,
    });
  }, []);

  const handleClick = () => {
    if (MOVIE?.isPremium && userSubscription === NO_ACTIVE_SUBSCRIPTION) {
      navigate("/subscriptions");
    } else {
      if (MOVIE.type === SERIES) {
        const keys = Object.keys(seasonsObject);
        const firstKey = keys[0];
         setMovieToWatch({ ...seasonsObject?.[firstKey]?.[0], movie: MOVIE });

      } else {
        setMovieToWatch(MOVIE);
      }
      setShowModal(true);
    }
  };
  const { user } = useAuthContext();

  return (
    <div dlassName="text-[#fff]">
      <div className="w-full md:aspect-[14/8] relative aspect-[20/8] bg-neutral-02">
        <img
          src={MOVIE?.previewUrl}
          className="absolute top-0 left-0 right-0 bottom-0 w-full h-full"
          alt={MOVIE?.title}
        />
        <div className="absolute top-0 right-0 bottom-0 left-0 bg-[#000]/30 z-10 flex items-center justify-center">
          <span className="text-[#fff] w-10 h-10">
            <PlayInCircle />
          </span>
        </div>
           
        {/* <video width="100%" height="100%" controls="controls">
                            
          <source
            src="https://vz-c873f947-600.b-cdn.net/86b5168e-5478-437c-8331-7fd0bfd70ccd/preview.webp"
            type="video/webp"
          />
              
        </video> */}
        {/* forceVideo: true,
          // */}
      </div>
      <div className="flex gap-2 items-center">
        {/* <Button iconPosition="left" icon={Videocorder}>
          Watch Trailer
        </Button> */}
        <Button
          iconPosition="left"
          icon={PlayInCircle}
          className="bg-[#676263] text-[#ECE6E6]  border-[#676263] hover:text-[#fff]"
          onClick={handleClick}
        >
          Play
        </Button>
      </div>
      {/* ///overview */}
      <div className="text-[#FFFFFF] mt-10 mb-8 px-4">
        <h2 className="text-h4 font-semibold">Overview</h2>
        <p className="my-2">{MOVIE?.description}</p>
      </div>

      {
        <section className="my-24">
          <div className="flex justify-between items-center">
            <p className="text-[#FFFF] ">{selectedSeason?.name}</p>
            <div className="md:w-[150px] w-[300px] max-w-[300px] my-3">
              <Select
                hidden={MOVIE?.type !== SERIES}
                handleSelectedItem={setSelectedSeason}
                items={seasons}
                selectedItem={selectedSeason}
                // labeflText="Seasons"
                isLoading={isLoading}
                itemsExist={!!seasons.length}
              />
            </div>
          </div>
          {MOVIE?.type === SERIES && (
            <EpisodeList
              MOVIE={MOVIE}
              // handleEpisodeClick={handleEpisodeClick}
              data={seasonsObject[selectedSeason?.value]}
              isLoading={isLoading}
              setMovieToWatch={setMovieToWatch}
              setShowModal={setShowModal}
              userSubscription={userSubscription}
            />
            // <ul className="text-[#FFF] md:space-y-3 space-y-0 my-2 md:block  movie-grid">
            //   {seasonsObject[selectedSeason?.value]?.map((item) => (
            //     <li className="">
            //       <EpisodeThumbnail
            //         movie={MOVIE}
            //         episode={item}
            //         handleEpisodeClick={handleEpisodeClick}
            //       />
            //     </li>
            //     // {

            //     // }
            //   ))}
            // </ul>
          )}
        </section>
      }

      <Comment user={user} />
      <MoviesModal
        showModal={showModal}
        setShowModal={setShowModal}
        movieToWatch={movieToWatch}
        setMovieToWatch={setMovieToWatch}
      />
    </div>
  );
};

export default Movie;
