import * as React from "react";
const SvgFolder = (props) => (
  <svg
    viewBox="0 0 20 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="m7.17 2 2 2H18v10H2V2h5.17ZM8 0H2C.9 0 .01.9.01 2L0 14c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2h-8L8 0Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgFolder;
