import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getStorage, ref, listAll } from "firebase/storage";
import { getAnalytics, logEvent } from "firebase/analytics";

var mixpanel = require("mixpanel-browser");

mixpanel.init(process.env.REACT_APP_TOKEN, { debug: true });
// mixpanel.track("An event");

// const storage = getStorage();
// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAP2bneY2u4o5OP_3Ov-cAGAsIkXGuR7Jk",
  authDomain: "waptv-49035.firebaseapp.com",
  projectId: "waptv-49035",
  storageBucket: "waptv-49035.appspot.com",
  messagingSenderId: "508169046001",
  appId: "1:508169046001:web:62879af890912cff892650",
  measurementId: "G-TRFBTLJPNC",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const storage = getStorage(app);
const analytics = getAnalytics(app);
const sendAnalyticsToFirebase = (event_name, event_parameter) => {
  logEvent(analytics, event_name, event_parameter);
  mixpanel.track(event_name, event_parameter);
};
export {
  auth,
  storage,
  listAll,
  ref,
  analytics,
  logEvent,
  sendAnalyticsToFirebase,
};
