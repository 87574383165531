import Button from "@app/components/common/Button";
import { useAuthContext } from "@app/utils/contexts.js/AuthProvider";
import React from "react";
import { useNavigate } from "react-router-dom";

const Logout = () => {
  const navigate = useNavigate();
  const { logout } = useAuthContext();
  const handleLogout = () => logout();
  return (
    <div className=" flex justify-center items-center text-[#fff] h-full w-full flex-auto">
      <div className="py-6 px-8  rounded-[4px] min-w-[280px] text-[#fafafa]">
        <h2 className="text-center font-medium md:text-h4 text-h5 mt-4">Signing Out?</h2>
        <p className="md:text-h3">Oh No! You are leaving Are you sure ?</p>

        <div className="flex justify-end gap-2 mt-6">
          <Button letterCase="" variant="link" theme="white" onClick={() => navigate(-1)}>
            Cancel
          </Button>
          <Button letterCase="" onClick={handleLogout}>
            Yes, log me out
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Logout;
