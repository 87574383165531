import React from "react";
import DropdownButton from "../common/DropdownButton";
import MultipleSelect from "../MultipleSelect/MultipleSelect";


const BTN_CLASS =
  " !md:py-[4px] py-[10px] w-[200px] px-[8px]  md:min-w-[50px] min-w-[100px] ";

const FiltersBanner = ({ selectedGenres, genres, setSelectedGenres }) => {
  // const [selectedGenres, setSelectedGenres] = useState([]);
  // const [selectedAge, setSelectedAge] = useState(AGE[0]);
  // const [selectedYear, setSelectedYear] = useState(YEARS[0]);
  // const [selectedAge, setSelectedAge] = useState(AGE[0]);

  const itemToString = (item) => (item ? item.name : "");
  const handleGenreSelect = (selectedGenres) => {
    // console.log({ selectedGenres });
    setSelectedGenres(selectedGenres);
  };

  return (
    <section className="flex justify-between mt-10 mb-8">
      <MultipleSelect
        itemToString={itemToString}
        onChange={handleGenreSelect}
        selectedItems={selectedGenres}
        items={genres}
        ButtonContent={
          <DropdownButton
            btnText="Genres"
            btnClass={BTN_CLASS + "justify-between"}
            iconPosition={"right"}
            isCaretIcon
            theme="white"
          />
        }
      />
      {/* <Select
        items={GENRES}
        className="w-fit"
        // selectedItem={selectedGenre}
        // handleSelectedItem={setSelectedGenre}
        ButtonContent={
          <DropdownButton
            btnText="Genres"
            btnClass={BTN_CLASS}
            iconPosition={"right"}
            isCaretIcon
          />
        }
      /> */}
      {/* <Select
        items={AGE}
        className="w-fit"
        selectedItem={selectedAge}
        handleSelectedItem={setSelectedAge}
        buttonClass={BTN_CLASS}
      /> */}
      {/* <Select
        items={YEARS}
        className="w-fit"
        selectedItem={selectedYear}
        handleSelectedItem={setSelectedYear}
        // buttonClass={BTN_CLASS}
        ButtonContent={
          <DropdownButton
            btnText="Year"
            btnClass={BTN_CLASS + " md:w-[80px] !justify-start"}
            // iconPosition={"le"}
            // isCaretIcon
            icon={Calendar}
          />
        }
        customList={<Dropdown.Item />}
      /> */}
    </section>
  );
};

export default FiltersBanner;
