import * as React from "react";
const SvgSquareRedLeftBlueTopRightSkyBottomRight = (props) => (
  <svg
    width={30}
    height={31}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path fill="#E90101" d="M.094.616h14.518v29.842H.094z" />
    <path fill="#5789DD" d="M14.612.616h15.324v29.842H14.612z" />
    <path fill="#C7D8F4" d="M14.612 15.135h15.324v15.324H14.612z" />
  </svg>
);
export default SvgSquareRedLeftBlueTopRightSkyBottomRight;
