import React from "react";
import duration from "dayjs/plugin/duration";
import dayjs from "dayjs";
dayjs.extend(duration);
const MovieProgress = ({ progress, duration }) => {
  // console.log({ progress, duration });
  return (
    <div className="w-full flex flex-col gap-1  ">
      <span className="w-full block bg-[#D9D9D9] h-[4px] rounded-[4px] overflow-hidden">
        <span
          className="h-full bg-primary-06 block"
          style={{ width: `${(progress * 100) / duration}%` }}
        ></span>
      </span>
      <span className="text-[10px] leading-l7">
        {`${Math.round(
          dayjs.duration(progress * 1000).asMinutes()
        )}  mins of ${Math.round(
          dayjs.duration(duration * 1000).asMinutes()
        )} mins watched`}
      </span>
    </div>
  );
};

export default MovieProgress;
