import React, { useEffect, useRef, useState } from "react";
import ReactPlayer from "react-player/lazy";
import MuiModal from "@mui/material/Modal";
import { useErrorHandler } from "@app/utils/hooks";
import { useUpdateWatchHistory } from "@app/utils/hooks/reactQuery/movie";
import EpisodesReel from "./components/EpisodesReel";

const MoviesModal = ({
  showModal,
  setShowModal,
  movieToWatch,
  setMovieToWatch,
}) => {
  const [progress, setProgress] = useState(null);
  const videoRef = useRef(null);
  const [duration, setDuration] = useState(null);
  const [nextEpisode, setNextEpisode] = useState(null);

  const {
    mutate,
    // data: updateHistoryResponse,
    // error,
  } = useErrorHandler(useUpdateWatchHistory);

  const handleProgress = (e) => {
    // console.log(e);
    setProgress(e.playedSeconds);
    // const interval = setInterval(() => mutate(payload), 1000);
    // setUpdateInterval(interval);
  };
  const onReady = React.useCallback(() => {
    const timeToStart = movieToWatch?.progress || 0;
    videoRef.current.seekTo(timeToStart, "seconds");
  }, [movieToWatch?.progress, movieToWatch]);

  useEffect(() => {
    let p;
    const payload = movieToWatch?.movie
      ? {
          movieId: movieToWatch?.movie?.id,
          newProgressData: {
            episodeId: movieToWatch?.id,
            duration: parseInt(duration),
            movieId: movieToWatch?.movie?.id,
            progress: parseFloat(progress),
            hasNext: false,
          },
        }
      : {
          movieId: movieToWatch?.id,
          newProgressData: {
            episodeId: movieToWatch?.id,
            duration: parseInt(duration),
            movieId: movieToWatch?.id,

            progress: parseFloat(progress),
            hasNext: false,
          },
        };
    if (showModal && progress && p !== progress) {
      mutate(payload);
      p = progress;
    }
  }, [
    duration,
    movieToWatch,
    movieToWatch?.id,
    movieToWatch?.movie,
    movieToWatch?.movieLength,
    mutate,
    progress,
    showModal,
  ]);

  const handlePlayerEnded = () => {
    if (!movieToWatch?.movie) {
      setShowModal(false);
      setMovieToWatch(null);
    } else {
      if (nextEpisode) {
        setMovieToWatch({ ...nextEpisode, movie: movieToWatch?.movie });
        setProgress(0);
        // videoRef.current.load(nextEpisode?.movieUrl || nextEpisode?.videoUrl);
      } else {
        setMovieToWatch(null);
        setShowModal(false);
      }
    }
  };

  return (
    <MuiModal
      open={showModal}
      onClose={() => setShowModal(false)}
      className="flex items-center justify-center "
      style={{
        opacity: 1,
        backgroundColor: "#000000",
      }}
    >
      <>
        {/* <p className="text-[#fff]">Name and Age</p> */}
        <div>
          <p className="text-h3 text-[#fff] text-center">
            Movie Title: {movieToWatch?.title}
          </p>
          <div className=" md:w-full mx-auto w-[700px] aspect-video">
            <ReactPlayer
              // className='react-player'
              config={{
                file: {
                  forceHLS: true,
                },
                // facebook: {
                //   appId: '12345'
                // }
              }}
              controls={true}
              ref={videoRef}
              url={
                movieToWatch?.movieUrl ||
                movieToWatch?.videoUrl ||
                movieToWatch?.link
              }
              width="100%"
              height="100%"
              onProgress={handleProgress}
              // onSeek={(e) => console.log("seek, ", e)}
              onReady={onReady}
              onDuration={(duration) => setDuration(duration)}
              fallback={movieToWatch?.previewUrl}
              onEnded={handlePlayerEnded}
              // loop={movieToWatch?.movie }
            />
          </div>
          {movieToWatch && movieToWatch?.movie && (
            <EpisodesReel
              movieToWatch={movieToWatch}
              setMovieToWatch={setMovieToWatch}
              movie={movieToWatch?.movie}
              setNextEpisode={setNextEpisode}
            />
          )}
        </div>
      </>
    </MuiModal>
  );
};

export default MoviesModal;
