import AccountPrivacyRight from '@app/components/AccountPrivacyRight'
import React from 'react'


const LIST_ITEMS = [
    {
        name: "Change Email",
        link: "/account/change-email"
    },
    {
        name: "Change Password",
        link: "/account/change-password"
    },
    // {
    //     name: "Change Phone Number",
    //     link: ""
    // },
    // {
    //     name: "Reset Password",
    //     link: ""
    // },
]

const AccountRight = () => {
  return (
    <AccountPrivacyRight data={LIST_ITEMS} page="account" title="Links to change details"/>
  )
}

export default AccountRight