import React from "react";
import WapTVLogo from "@app/assets/images/wapTvLogo.png";
import classNames from "classnames";
import { Link } from "react-router-dom";
// import WapTVLogo from "../../../assets/images/Logo.png";

const Logo = ({ className, isCollapsed = false, hide = true }) => {
  return (
    // specific for mobile, works in conjunction with search component in header component
    <Link to="/" className="peer-focus-within:md:hidden">
      <figure
        className={classNames("inline-block flex-shrink-0  ", {
          "md:hidden": hide,
          "md:block": !hide,
        })}
      >
        <img
          src={WapTVLogo}
          alt="Wap TV Logo"
          className={classNames(
            "inline-block md:ml-0",
            {
              "w-[48px] ": isCollapsed,
              "w-16 ": !isCollapsed,
            },
            className
          )}
        />
      </figure>
    </Link>
  );
};

export default Logo;
