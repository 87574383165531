import MovieProgress from "@app/components/MoviesRow/MovieProgress";
import { NO_ACTIVE_SUBSCRIPTION, SERIES } from "@app/constants";
import { useErrorHandler } from "@app/utils/hooks";
import { useWatchHistory } from "@app/utils/hooks/reactQuery/movie";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import EpisodeThumbnail from "./EpisodeThumbnail";

const EpisodeList = ({
  data,
  MOVIE,
  isLoading,
  userSubscription,
  setMovieToWatch,
  setShowModal,
}) => {
  const navigate = useNavigate();
  const { data: watchHistory, isLoading: isLoadingWatchHistory } =
    useErrorHandler(useWatchHistory);

  const [unFinished, setUnFinished] = useState({});

  useEffect(() => {
    if (!isLoadingWatchHistory && watchHistory) {
      setUnFinished((unFinished) => []);
      watchHistory.forEach((item) => {
        // if (item.movie.type !== SERIES) {
        //   const movie = item?.movie;
        //   movie.progress = item?.lastWatched?.progress;
        //   movie.movieLength = item?.lastWatched?.duration;
        //   setUnFinished((unFinished) => [...unFinished, movie]);
        // } else {
        if (item.movie.type === SERIES) {
          const movie = item?.movie;
          // const episodes =
          item.episodeProgress.forEach((item) => {
            const episode = {
              ...item,
              title: `${movie.title}[season_${item.season}_episode_${item.episodeNumber}]`,
              movieTitle: movie.title,
              id: `${item.episodeId}`,
              movieLength: item.duration,
              movieUrl: item.videoUrl,
              previewUrl: item.previewUrl,
              movie,
            };
            setUnFinished((unFinished) => ({
              ...unFinished,
              [episode.id]: episode,
            }));
          });
        }
        // setUnFinished((unFinished) => [...unFinished, ...episodes]);
        // }
      });
    }
  }, [isLoadingWatchHistory, watchHistory]);

  console.log({ unFinished });
  const handleEpisodeClick = (episode) => {
    if (!isLoading) {
      if (MOVIE?.isPremium && userSubscription === NO_ACTIVE_SUBSCRIPTION) {
        navigate("/subscriptions");
      } else {
        // console.log({ episode, unFinished });

        const newObject = {
          ...episode,
          movieUrl: episode.link,
          movie: MOVIE,
          progress: unFinished[episode?.id]?.progress || 0,
        };
        setMovieToWatch(newObject);
        setShowModal(true);
      }
    }
  };

  return (
    <ul className="text-[#FFF] md:space-y-3 space-y-0 my-2 md:block  movie-grid">
      {data?.map((item) => (
        <li className="">
          <EpisodeThumbnail
            movie={MOVIE}
            episode={item}
            handleEpisodeClick={handleEpisodeClick}
          />
          {unFinished?.[item?.id] && (
            <div className="w-[80%] mx-auto my-1">
              <MovieProgress
                progress={unFinished?.[item?.id]?.progress}
                duration={unFinished?.[item?.id]?.movieLength}
              />
            </div>
          )}
        </li>
        // {

        // }
      ))}
    </ul>
  );
};

export default EpisodeList;
