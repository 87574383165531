import classNames from "classnames";
import React from "react";
import { NavLink, useLocation } from "react-router-dom";

const Count = ({ count, isCollapsed = false }) => {
  return (
    <span
      className={classNames(
        "text-[10px] bg-information p-2 rounded-full leading-[0.6] text-[#1C1D22]",
        {
          "absolute top-0 right-0 -translate-y-[50%] translate-x-[50%]":
            isCollapsed,
        }
      )}
    >
      {count}
    </span>
  );
};

const MenuItem = ({ item, isCollapsed, state }) => {
  // const count = 0;
  const { pathname } = useLocation();
  const location = useLocation();
  // console.log({ pathname, state });
  return (
    <NavLink
      to={item.link === "/home" ? "/" : item.link}
      state={item.link === "/notifications" && { backgroundLocation: location }}
    >
      <li
        className={classNames(
          "flex gap-3 items-center  hover:bg-grey-200 rounded-[8px] py-3 px-3 text-h2  transition-all ease-in duration-300 ",
          {
            "w-max px-3": isCollapsed,
            "hover:bg-[#000]/30": !pathname.includes(item.link),
            "text-black-01 bg-primary-06":
              location?.state !== "opened"
                ? pathname.includes(item.link) ||
                  (item.link === "/home" && pathname === "/")
                : item.link === "/notifications",
          }
        )}
      >
        <span className="w-5 h-5 flex items-center justify-center text-inherit  relative">
          {<item.icon />}
          {!isNaN(parseInt(item?.value)) && isCollapsed && (
            <Count count={0} isCollapsed={true} />
          )}
        </span>
        {!isCollapsed && <p>{item.name}</p>}
        {!isNaN(parseInt(item?.value)) && !isCollapsed && (
          <Count count={item.value} />
        )}
      </li>
    </NavLink>
  );
};

export default MenuItem;
