import { getComments, postComment } from "@app/api/commentapi";

const {
  useQueryClient,
  useMutation,
  useInfiniteQuery,
} = require("react-query");

// const fetchProjects = ()

const useInfiniteComments = (movieId, limit) => {
  const {
    data,
    error,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isFetchingNextPage,
    status,
    isLoading,
    isError,
  } = useInfiniteQuery(
    `comments ${movieId}`,
    ({ pageParam: lastCommentId }) => {
      // console.log({ pageParam });
      return getComments(movieId, limit, lastCommentId);
    },

    {
      keepPreviousData: true,
      refetchInterval: 10800,
      getNextPageParam: (lastComment, allComments) => {
        // console.log({ allComments });
        const data = allComments[allComments.length - 1]?.data;
        const last = data?.[data.length - 1];

        // console.log({ data, last });
        return last?.id;
      },
    }
  );
  return {
    data,
    error,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isFetchingNextPage,
    status,
    isLoading,
    isError,
  };
};

const useAddComment = (movieId) => {
  const queryClient = useQueryClient();
  const { mutate, data, isError, isSuccess, isLoading } = useMutation({
    mutationFn: (comment, user) => postComment(movieId, comment),
    // onMutate: async (newComment) => {
    //   // Cancel any outgoing refetches
    //   // (so they don't overwrite our optimistic update)
    //   await queryClient.cancelQueries({ queryKey: "comments" });

    //   // Snapshot the previous value
    //   const previousComments = await queryClient.getQueryData("comments");
    //   // console.log({ previousComments });
    //   // Optimistically update to the new value
    //   queryClient.setQueryData("comments", (old) => [{movieId, content: newComment, },...old]);

    //   // Return a context object with the snapshotted value
    //   return { previousComments };
    // },
    // // If the mutation fails,
    // // use the context returned from onMutate to roll back
    // onError: (err, newComment, context) => {
    //   queryClient.setQueryData("comments", context.previousComments);
    // },
    // // Always refetch after error or success:
    // onSettled: () => {
    //   queryClient.invalidateQueries({ queryKey:"comments" });
    // },

    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: `comments ${movieId}` });
    },
  });
  return { mutate, isLoading, data, isError, isSuccess };
};

export {
  useInfiniteComments,
  useAddComment,

  // , useEditGenre,
  //  useAddGenre
};
