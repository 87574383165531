import * as React from "react";
const SvgCaretRight = (props) => (
  <svg
    width={6}
    height={11}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M.3 10.054a.73.73 0 0 0 1.013 0L5.37 6.11a.683.683 0 0 0 0-.985L1.313 1.178a.73.73 0 0 0-1.012 0 .683.683 0 0 0 0 .985l3.55 3.453L.301 9.07a.683.683 0 0 0 0 .984Z"
      fill="#fff"
    />
  </svg>
);
export default SvgCaretRight;
