import * as React from "react";
const SvgExit = (props) => (
  <svg
    // width={15}
    // height={15}
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M14.016 6.38951V5.45651C14.016 3.42151 12.366 1.77151 10.331 1.77151H5.45597C3.42197 1.77151 1.77197 3.42151 1.77197 5.45651V16.5865C1.77197 18.6215 3.42197 20.2715 5.45597 20.2715H10.341C12.37 20.2715 14.016 18.6265 14.016 16.5975V15.6545"
      stroke="currentColor"
      strokeOpacity="1"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20.8096 11.0214H8.76855"
      stroke="currentColor"
      strokeOpacity="0.7"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.8813 8.10632L20.8093 11.0213L17.8813 13.9373"
      stroke="currentColor"
      strokeOpacity="0.7"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default SvgExit;
