import React from "react";
import MenuItem from "./MenuItem";
import MenuList from "./MenuList";

const Menu = ({ children }) => {
  return <>{children}</>;
};

Menu.List = MenuList;
Menu.Item = MenuItem;
export default Menu;
