import React, { useMemo } from "react";
import Sidebar from "@app/components/common/Sidebar";
import Header from "@app/components/common/Header";
import {
  Bell,
  Exit,
  Folder,
  // FourRoundedSquares,
  Heart,
  Home,
  Movies,
  Privacy,
  Question,
  // Settings,
  Subscription,
} from "@app/components/Icon/icons";
import { Outlet } from "react-router-dom";
// import GlobalStateProvider from "@app/utils/contexts.js/GlobalStateProvider";
// import ManageAdmins from "@app/views/ManageAdmins";
// import { Outlet } from "react-router-dom";
// import { Dashboard } from "@app/views";
// import SvgExit from "@app/components/Icon/icons/Exit";

const MAIN_MENU_ITEM = [
  {
    name: "Home",
    link: "/home",
    icon: Home,
  },
  {
    name: "Movies",
    link: "/movies",
    icon: Movies,
    hasCount: true,
  },
  // {
  //   name: "Tv Series",
  //   link: "/series",
  //   icon: Livetv,
  // },

  {
    name: "WatchList",
    link: "/list",
    icon: Folder,
    auth: true,
  },
  {
    name: "Favourites",
    link: "/favourites",
    icon: Heart,
    auth: true,
  },
  {
    name: "Subscription",
    link: "/subscriptions",
    icon: Subscription,
    auth: true,
  },
];

const SUB_MENU_ITEMS = [
  {
    name: "Contact Us",
    link: "/contact-us",
    icon: Question,
  },
  {
    name: "Privacy Policy",
    link: "/privacy-policy",
    icon: Privacy,
  },
  {
    name: "Logout",
    link: "/logout",
    icon: Exit,
    auth: true,
  },
];

const DashboardLayout = ({

  state,
  isSidebarCollapsed,
  setIsSidebarCollapsed,
}) => {
  // console.log({ state });
  // const Notification = useMemo(() => {
  //   return {
  //     name: "Notifications",
  //     link: "/notifications",
  //     hasCount: true,
  //     icon: Bell,
  //     auth: true,
  //     value: notificationsNumber,
  //   };
  // }, [notificationsNumber]);
  return (
    <div className="flex overflow-y-hidden h-screen">
      <Sidebar
        main={[...MAIN_MENU_ITEM]}
        // main={[...MAIN_MENU_ITEM, Notification]}
        sub={SUB_MENU_ITEMS}
        notificationModalstate={state}
        isCollapsed={isSidebarCollapsed}
        setIsCollapsed={setIsSidebarCollapsed}
      />

      {/* <MainSection> */}

      <div className="flex flex-col flex-auto  w-full overflow-hidden">
        <Header />
        <section className="overflow-y-auto flex flex-col items-stretch scrollbar-hide md:px-1 px-4  flex-auto pb-16 relative">
          <Outlet />
        </section>
      </div>
      {/* </GlobalStateProvider> */}
      {/* </MainSect/ion> */}
    </div>
  );
};

export default DashboardLayout;
