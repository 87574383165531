// import { CircleRedLeftBlueRight } from "@app/components/Icon/icons";
import { useErrorHandler } from "@app/utils/hooks";
import { useSubscriptions } from "@app/utils/hooks/reactQuery/subscription";
import React from "react";
import SubscriptionCard from "./components/SubscriptionCard";
import SubscriptionPlaceholder from "./components/SubscriptionPlaceholder";

const Subscriptions = () => {
  const { data: subscriptionPlans, isLoading: isLoadingSubscriptionPlans } =
    useErrorHandler(useSubscriptions);

  return (
    <section className="my-4">
      <h2 className="page-title md:mb-1 mb-2">Simple , transparent pricing</h2>
      {/* <p className="md:w-[90%] w-1/2 mx-auto md:text-h2 text-center text-[#fff]">
        Lorem, ipsum dolor sit amet consectetur adipisicing elit. Asperiores
      </p> */}
      <div className="md:block flex gap-3 justify-center flex-wrap md:space-y-6 my-4">
        {isLoadingSubscriptionPlans
          ? new Array(8).fill(1).map((item) => <SubscriptionPlaceholder />)
          : subscriptionPlans?.map((item) => (
              <SubscriptionCard
                isLoading={isLoadingSubscriptionPlans}
                plan={item?.duration}
                description={item?.description}
                title={item?.title}
                price={item?.price}
                key={subscriptionPlans?.id}
                subscription={item}
              />
            ))}
      </div>
    </section>
  );
};

export default Subscriptions;
