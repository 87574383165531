import * as React from "react";
const SvgKeyInsideRoundCorneredSquare = (props) => (
  <svg
  viewBox="0 0 22 22" 
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M15.334 1.75H6.665c-3.021 0-4.915 2.139-4.915 5.166v8.168c0 3.027 1.885 5.166 4.915 5.166h8.668c3.031 0 4.917-2.139 4.917-5.166V6.916c0-3.027-1.886-5.166-4.916-5.166Z"
      stroke="#fff"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      clipRule="evenodd"
      d="M9.69 11a1.852 1.852 0 1 1-1.853-1.852h.003A1.852 1.852 0 0 1 9.69 11Z"
      stroke="#fff"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.692 11h6.318v1.852M13.182 12.852V11"
      stroke="#fff"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default SvgKeyInsideRoundCorneredSquare;
