import * as React from "react";
const SvgShieldWithTickAtCenter = (props) => (
  <svg
    viewBox="0 0 76 92"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M37.777.167.277 16.834v25c0 23.125 16 44.75 37.5 50 21.5-5.25 37.5-26.875 37.5-50v-25L37.777.167Zm29.167 41.667c0 18.833-12.417 36.208-29.167 41.375C21.027 78.042 8.61 60.667 8.61 41.834V22.25L37.777 9.292 66.944 22.25v19.584Zm-48.292 2.458-5.875 5.875 16.667 16.667L62.777 33.5l-5.875-5.916-27.458 27.458-10.792-10.75Z"
      fill="#ED2B2B"
    />
  </svg>
);
export default SvgShieldWithTickAtCenter;
