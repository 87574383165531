import { CaretLeft, CaretRight } from "@app/components/Icon/icons";
import classNames from "classnames";
import React from "react";

const Collapser = ({ isCollapsed = false, setIsCollapsed, className }) => {
  return (
    <button
      className={classNames(
        "w-7 h-7 justify-center items-center inline-flex rounded-[4px] bg-[#3c3c3c] cursor-pointer ",

        className
      )}
      onClick={() => setIsCollapsed((collapsed) => !collapsed)}
    >
      {isCollapsed ? <CaretRight /> : <CaretLeft />}
    </button>
  );
};

export default Collapser;
