import { resetPassword } from "@app/api/authapi";
import Input from "@app/components/common/Input";
import Toast from "@app/components/common/Toast";
import { storage } from "@app/utils";
import { useFormik } from "formik";
import React from "react";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import AuthForm from "../components/AuthForm";
import * as Yup from "yup";
import { sendAnalyticsToFirebase } from "@app/config/firebase.config";

const ResetPassword = () => {
  const [isResettingPassword, setIsResettingPassword] = useState(false);
  const navigate = useNavigate();
  const EMAIL = storage.get("email");
  const ID = storage.get("id");
  const OTP = storage.get("otp");

  if (!OTP || !ID || !EMAIL) {
    navigate("/auth/forgot");
    storage.clear("email");
    storage.clear("id");
    storage.clear("otp");
  }

  const formik = useFormik({
    initialValues: {
      password: "",
    },
    validationSchema: Yup.object({
      password: Yup.string()
        .min(8, "Password must be at least 8 characters long!")
        .required("This field is required!"),
      // token: Yup.string().email('Invalid email address').required('Required'),
    }),
    onSubmit: (values) => {
      // alert(JSON.stringify(values, null, 2));
      setIsResettingPassword(true);
      const payload = {
        ...values,
        email: storage.get("email"),
        id: storage.get("id"),
        code: storage.get("otp"),
      };

      resetPassword(payload)
        .then((res) => {
          if (res.statusCode === 200) {
            toast.success(<Toast message={res.message} toastType="success" />, {
              toastId: "toast-reset-password-successfully",
            });
            sendAnalyticsToFirebase("password_reset");
            navigate("/auth/success");
            storage.clear("id");
            storage.clear("otp");
            storage.clear("email");
            storage.clear("verifyFor");
          }
        })
        .catch((e) => console.log("error in resetting password page: ", e))
        .finally(() => {
          setIsResettingPassword(false);
        });
      // .then((e) => navigate("/auth/reset-success"))
    },
  });

  return (
    <AuthForm
      handleSubmit={formik.handleSubmit}
      isLoading={isResettingPassword}
      text="Reset password"
      showSocials={false}
      title={"Password reset"}
      description={"Enter your new password."}
      belowButtonText={
        <>
          {" "}
          Remembered your password?{" "}
          <Link to="/auth/signin">
            <span className="text-primary-06 hover:underline hover:underline-offset-2">
              Sign in
            </span>
          </Link>
        </>
      }
    >
      <Input
        onChange={formik.handleChange}
        value={formik.values.password}
        name="password"
        onBlur={formik.handleBlur}
        labelTitle="Password"
        loading={isResettingPassword}
        // placeholder={"JohnDoe@gmail.com"}
        showEyeIcon={true}
        variant="transparent"
        //   key={index}
        message={
          formik.touched.password &&
          formik.errors.password && {
            type: "error",
            value: formik.errors.password,
          }
        }
        //   helperText={item.helperText}
      />
    </AuthForm>
  );
};

export default ResetPassword;
