import {
  addToFavourites,
  removeFromFavourites,
  getFavourites,
} from "@app/api/favoriteapi";

const { useQuery, useMutation, useQueryClient } = require("react-query");

// const "favourites" = ["favourites"];

const useFavorites = () => {
  const { isFetching, data, isError, error, isLoading } = useQuery({
    queryKey: "favourites",
    queryFn: getFavourites,
    retry: false,
  });
  return { isFetching, data, isError, error, isLoading };
};

const useAddFavourite = (movies) => {
  const queryClient = useQueryClient();
  const { mutate, data, isError, isSuccess, isLoading } = useMutation({
    mutationFn: addToFavourites,
    // mutationKey: ['favourites' , slug],
    onMutate: async (movie) => {
      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({ queryKey: "favourites" });

      // Snapshot the previous value
      const previousFavourites = await queryClient.getQueryData("favourites");
    //   console.log({ previousFavourites });
      // Optimistically update to the new value
      queryClient.setQueryData("favourites", (old) => {
        // const flattenedMoviesArray = movies.map((item) => item.data).flat(1);
        // const movieToFavourite = flattenedMoviesArray.find(
        //   (movie) => movieId === movie.id
        // );
        return [...old, movie];
      });

      // Return a context object with the snapshotted value
      return { previousFavourites };
    },
    // If the mutation fails,
    // use the context returned from onMutate to roll back
    onError: (err, newFavorite, context) => {
      queryClient.setQueryData("favourites", context.previousFavourites);
    },
    // Always refetch after error or success:
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: "favourites" });
    },
    // onSuccess: () => {
    //   // Invalidate and refetch
    //   queryClient.invalidateQueries({ queryKey: "favourites" });
    // },
  });
  return { mutate, isLoading, data, isError, isSuccess };
};

const useRemoveFavourite = (movies) => {
  const queryClient = useQueryClient();
  const { mutate, data, isError, isSuccess, isLoading } = useMutation({
    mutationFn: removeFromFavourites,
    // mutationKey: ['favourites' , slug],
    onMutate: async (movieId) => {
      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({ queryKey: "favourites" });

      // Snapshot the previous value
      const previousFavourites = await queryClient.getQueryData("favourites");
    //   console.log({ previousFavourites });
      // Optimistically update to the new value
      queryClient.setQueryData("favourites", (old) => {
        return old.filter((item) => item.id !== movieId);
      });

      // Return a context object with the snapshotted value
      return { previousFavourites };
    },
    // If the mutation fails,
    // use the context returned from onMutate to roll back
    onError: (err, newFavorite, context) => {
      queryClient.setQueryData("favourites", context.previousFavourites);
    },
    // Always refetch after error or success:
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: "favourites" });
    },
    // onSuccess: () => {
    //   // Invalidate and refetch
    //   queryClient.invalidateQueries({ queryKey: "favourites" });
    // },
  });
  return { mutate, isLoading, data, isError, isSuccess };
};

export { useFavorites, useRemoveFavourite, useAddFavourite };
