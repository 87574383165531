import { CaretLeft, CaretRight } from '@app/components/Icon/icons'
import classNames from 'classnames'
import React from 'react'

const Chevron = ({direction,handleClick , className}) => {
  return (
    <button
    className={classNames(
      "w-7 h-7 justify-center items-center inline-flex rounded-[4px] bg-[#3c3c3c] cursor-pointer " , className )}
    onClick={handleClick}
  >
    {direction === "right" ? <CaretRight /> : <CaretLeft />}
  </button>
  )
}

export default Chevron