import * as React from "react";
const SvgArrowDropddownCircle = (props) => (
  <svg
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12 3.167c5.145 0 9.333 4.188 9.333 9.333 0 5.145-4.188 9.333-9.333 9.333-5.145 0-9.333-4.188-9.333-9.333 0-5.145 4.188-9.333 9.333-9.333Zm0-2.334C5.56.833.333 6.06.333 12.5.333 18.94 5.56 24.167 12 24.167c6.44 0 11.667-5.227 11.667-11.667C23.667 6.06 18.44.833 12 .833ZM12 16l-4.667-4.667h9.334L12 16Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgArrowDropddownCircle;
