// import React, { useState } from "react";
import Logo from "@app/components/common/Logo";
// import { Link } from "react-router-dom";
import classNames from "classnames";
// import { useLocation } from "react-router-dom";
import Collapser from "./components/Collapser";
import Menu from "@app/components/Menu";
import { useGlobalStateContext } from "@app/utils/contexts.js/GlobalStateProvider";
import useWindowSize from "@app/utils/hooks/useWindowSize";
import User from "./components/User.jsx/User";
import { useAuthContext } from "@app/utils/contexts.js/AuthProvider";
import { Link } from "react-router-dom";
import { MAX_MOBILE_WIDTH } from "@app/constants";
const Sidebar = ({ main, sub, notificationModalstate, isCollapsed,setIsCollapsed }) => {
  const { user } = useAuthContext();
  const { state } = useGlobalStateContext();
  const { width } = useWindowSize();

  // const movies = 3;
  // const notifications = 6;
  // const location = useLocation();
  // const { pathname } = useLocation();

  return (
    <div
      className={classNames(
        "max-w-[230px]  md:min-h-[600px] min-h-screen bg-[#ffffff] shrink-0 static transition-all ease-in duration-150  md:fixed md:w-full md:h-[60vh] z-[1000] top-20",
        {
          "w-[24.5vw] min-w-[220px]": !isCollapsed,
          "md:hidden": !state.isNavOpen,
          // "w-fit": isCollapsed,
        }
      )}
    >
      <nav
        onClick={(e) => e.stopPropagation()}
        className={classNames(
          "w-full h-full px-4 py-4 bg-[#262626] text-[#ffffff] relative flex flex-col",
          {
            "px-2 items-center": isCollapsed,
          }
        )}
      >
        <Logo isCollapsed={isCollapsed} />

        {width > MAX_MOBILE_WIDTH && (
          <Collapser
            isCollapsed={isCollapsed}
            setIsCollapsed={setIsCollapsed}
            className="absolute right-0 translate-x-1/2 top-10 "
          />
        )}

        <div className="mt-8 mb-2">
          <Link to="/account">
            <User isCollapsed={isCollapsed} name={user?.fullName} />
          </Link>
        </div>
        <div className="flex-auto flex justify-between md:justify-start flex-col">
          <Menu>
            <Menu.List>
              {main.map((item, index) => {
                if (item.auth && !user) {
                  // eslint-disable-next-line array-callback-return
                  return;
                }
                return (
                  <Menu.Item
                    key={index}
                    item={item}
                    isCollapsed={isCollapsed}
                  />
                );
              })}
            </Menu.List>
          </Menu>
          <Menu>
            <Menu.List>
              {sub.map((item, index) => (
                <Menu.Item
                  key={index}
                  item={item}
                  isCollapsed={isCollapsed}
                  state={notificationModalstate}
                />
              ))}
            </Menu.List>
          </Menu>
        </div>
      </nav>
    </div>
  );
};

export default Sidebar;
