import React from "react";

const Card = ({ heading, children }) => {
  return (
    <section  className="w-full">
      <h2 className="text-[#fff] text-h5 font-semibold md:text-h4 my-1 px-4">
        {heading}
      </h2>
      <div className="bg-black-04 px-4 py-7 flex flex-col gap-2 text-[#fff]">
        {children}
      </div>
    </section>
  );
};

export default Card;
