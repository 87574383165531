import DummyProfilePicture from "@app/components/DummyProfilePicture";
import { ClockArrow } from "@app/components/Icon/icons";
import React from "react";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";

dayjs.extend(relativeTime);

const CommentItem = ({ comment, index }) => {
  return (
    <li className="flex md:gap-3 gap-4 text-[#DAD9DA] justify-center bg-[#262626] md:justify-start md:p-4 py-5 px-20">
      <DummyProfilePicture
        className="flex-shrink-0"
        backgroundType={index % 2 ? "red" : "blue"}
      >
        {comment?.author?.name?.[0]}
      </DummyProfilePicture>
      <div className="flex gap-3 items-start md:flex-col flex-auto justify-between ">
        <div className="">
          <p className="text-h3 text-[#fff] font-semibold">
            {comment?.author?.name}
          </p>
          <p className="max-w-3xl text-h2">{comment?.content}</p>
        </div>
        <div className="flex gap-2 items-start min-w-32 flex-shrink-0">
          <span className="w-4 h-4 inline-block ">
            <ClockArrow />
          </span>
          <p className="text-h1 font-semibold">
            {dayjs(new Date(comment?.createdAt?._seconds * 1000)).fromNow()}
          </p>
        </div>
      </div>
    </li>
  );
};

export default CommentItem;
