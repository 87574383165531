import Skeleton from "@app/components/common/Skeleton";

const SubscriptionPlaceholder = () => {
  return (
    <div className="bg-[#fff] max-w-xs p-7  rounded-2xl mx-auto">
      <div className="flex items-center  gap-3">
        <Skeleton height={50} width={50} className="rounded-[12px]" />

        <div className="flex flex-col gap-[1px] ">
          <Skeleton width={100} height={18} />

          <Skeleton width={180} height={18} />
        </div>
      </div>

      <Skeleton fullWidth height={100} className="block mt-5 mb-8"/>

      <Skeleton className={"rounded-full py-[28px] text-h5"} fullWidth/>
    </div>
  );
};

export default SubscriptionPlaceholder;
