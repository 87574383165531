import Button from "@app/components/common/Button";
import Skeleton from "@app/components/common/Skeleton";
import {
  Envelope,
  KeyInsideRoundCorneredSquare,
} from "@app/components/Icon/icons";
import { useAuthContext } from "@app/utils/contexts.js/AuthProvider";
import { useErrorHandler } from "@app/utils/hooks";
import {
  useSubscriptions,
  useUserSubscription,
} from "@app/utils/hooks/reactQuery/subscription";
import dayjs from "dayjs";
import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import Card from "./components/Card";

import LocalizedFormat from "dayjs/plugin/localizedFormat";
dayjs.extend(LocalizedFormat);

// dayjs().format("L LT");

const AccountLeft = () => {
  const { data: subscriptionPlans, isLoading: isLoadingSubscriptionPlans } =
    useErrorHandler(useSubscriptions);
  const { data: userSubscription, isLoading: isLoadingUserSusbcriptionPlan } =
    useErrorHandler(useUserSubscription);
  // console.log({ userSubscription });
  const { user } = useAuthContext();
  // console.log({subscriptionPlans})
  const plan = useMemo(
    () =>
      subscriptionPlans?.find(
        (item) => item.planCode === userSubscription?.planCode
      ),
    [subscriptionPlans, userSubscription?.planCode]
  );
  // console.log({ plan \s});
  return (
    <div className="md:space-y-4 space-y-4">
      <Card heading={"User Profile"}>
        <p className="flex items-center gap-4">
          <span className="md:w-5 md:h-5 w-7 h-7 inline-block">
            <Envelope />
          </span>
          <span className="inline-block md:text-h2 text-h4">{user?.email}</span>
        </p>
        <p className="flex items-center gap-4">
          <span className="md:w-5 md:h-5 w-7 h-7 inline-block">
            <KeyInsideRoundCorneredSquare />
          </span>
          <span className="inline-block">********</span>
        </p>
      </Card>

      {userSubscription?.dateSubscribed && (
        <Card heading="Payment Details">
          {isLoadingUserSusbcriptionPlan ? (
            <>
              <Skeleton width={340} height={15} />
              <Skeleton width={340} height={15} />
            </>
          ) : userSubscription?.dateSubscribed ? (
            <>
              <p className="md:text-h2">
                <span className="font-semibold ">Subscription date: </span>
                {dayjs(
                  new Date(userSubscription?.dateSubscribed?._seconds * 1000)
                ).format("LLLL")}
              </p>
              <p className="md:text-h2">
                <span className="font-semibold">Next Billing date: </span>
                {dayjs(
                  new Date(userSubscription?.nextBillingOn?._seconds * 1000)
                ).format("LLLL")}
              </p>
            </>
          ) : (
            <p className="md:text-h2">No subscription yet!</p>
          )}
        </Card>
      )}
      <Card heading={"Subscription Details"}>
        {isLoadingSubscriptionPlans && isLoadingUserSusbcriptionPlan ? (
          <>
            <Skeleton width={340} height={15} />
            <Skeleton width={340} height={15} />
            <Skeleton width={340} height={15} />
          </>
        ) : userSubscription?.dateSubscribed ? (
          <>
            <p className="flex gap-2">
              {userSubscription?.planTitle}
              <span className="font-semibold">NGN {plan?.price}</span>
            </p>
            <p className="md:text-h2">
              <span className="font-semibold">
                Subscription Plan Duration:{" "}
              </span>
              {userSubscription?.duration}
            </p>

            <Link to="/subscriptions">
              <Button
                className={
                  "text-primary-05 focus:bg-none text-left w-max !p-0 m-0"
                }
                variant="link"
              >
                Change Plan
              </Button>
            </Link>
          </>
        ) : (
          <>
            <p className="md:text-h2">No subscription yet!</p>
            <Link to="/subscriptions">
              <Button
                className={
                  "text-primary-05 focus:bg-none text-left w-max !p-0 m-0"
                }
                variant="link"
              >
                Subscribe to a Plan
              </Button>
            </Link>
          </>
        )}
      </Card>
    </div>
  );
};

export default AccountLeft;
