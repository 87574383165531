import * as React from "react";
const SvgAirplane = (props) => (
  <svg
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M13.832 6.675 8.11 12.459l-6.51-4.071c-.932-.584-.738-2 .317-2.31L17.37 1.554c.966-.283 1.862.62 1.575 1.589l-4.573 15.445c-.313 1.056-1.722 1.245-2.3.308L8.106 12.46"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default SvgAirplane;
