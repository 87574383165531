import classNames from "classnames";
import React from "react";
import { Link } from "react-router-dom";

const AccountPrivacyRight = ({ data, title, page }) => {
  return (
    <div className="py-8 px-6">
      <h2 className="text-h4 font-medium">{title}</h2>
      <ul className="my-3">
        {data.map((item, index) => (
          <Link to={item.link} key={`index_${index}`}>
            <span
              className={classNames(
                " hover:underline underline-offset-2 block mb-5",
                {
                  "text-primary-06": page === "account",
                  "text-secondary-07": page === "privacy",
                }
              )}
            >
              {item.name}
            </span>
          </Link>
        ))}
      </ul>
    </div>
  );
};

export default AccountPrivacyRight;
