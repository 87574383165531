import { CallSupport } from "@app/components/Icon/icons";
import FaqPrivacyAccountLayout from "@app/layouts/FaqPrivacyAccountLayout";
import React from "react";
import FaqLeft from "./FaqLeft";
// import FaqRight from "./FaqRight";

const Faq = () => {
  return (
    <FaqPrivacyAccountLayout
      bannerHeader="Contact Us"
      bannerFollowupText="Feel free to reach out to us."
      icon={CallSupport}
      left={<FaqLeft />}
      // right={<FaqRight/>}
    />
  );
};

export default Faq;
